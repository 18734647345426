<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: John David Vásquez Serna                                      ###### -->
<!-- ###### @date: Marzo 2024                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <div class="d-flex align-center pa-2 header">
    <v-list-item-avatar class="quitarRadio">
      <v-img src="../assets/herinco-small.png" />
    </v-list-item-avatar>
    <img src="../assets/images/logoCohan.png" />
    <v-spacer></v-spacer>
    <span v-if="!screenSmaller" class="textAnimate d-flex align-center justify-center" color="blue darken-4">COOPERATIVA DE HOSPITALES DE ANTIOQUIA</span>
    <span v-if="screenSmaller" class="textAnimate d-flex align-center justify-center" color="blue darken-4">COHAN</span>
    <v-btn v-if="!screenSmaller" rounded class="white--text" color="#0d47a1" @click="() => logout()">
      <v-icon class="me-2">logout</v-icon>
      <span>Salir</span>
    </v-btn>
    <v-btn v-if="screenSmaller" icon rounded class="white--text" style="background-color: #0d47a1;"  color="#0d47a1" @click="() => logout()">
      <v-icon>logout</v-icon>
    </v-btn>
  </div>
</template>
  
<!-- #################################################################################### -->
<!-- ###### Sección de Scripts                                                     ###### -->
<!-- #################################################################################### -->
<script>
export default {
  name: "HeaderAffiliates",
  props: {
    user: {
      type: String,
      default: "Aser"
    },
    rol: {
      type: Array,
      default: () => ["rol"]
    },
    logout: {
      type: Function,
      default: () => { }
    }
  },
  data: () => ({
    menu: false,
    screenSmaller: false
  }),
  mounted() {
    this.checkScreenSize();
    window.addEventListener('resize', this.checkScreenSize);
  },
  methods: {
    checkScreenSize() {
      this.screenSmaller = window.innerWidth <= 542;
    }
  },
}
</script>
  
<!-- #################################################################################### -->
<!-- ###### Sección de Estilos                                                     ###### -->
<!-- #################################################################################### -->
<style scoped>
@import url(https://fonts.googleapis.com/css?family=Open+Sans:700);

.header {
  border-bottom: solid 1px rgb(218, 217, 217);
  background-color: white;
  position: sticky;
  top: 0;
  height: 53px !important;
  z-index: 100
}

.quitarRadio {
  border-radius: 0%;
}

.textAnimate {
  width: 100%;
  color: #0D47A1;
  font-family: 'Open Sans', sans-serif;
  font-size: 1em;
  -webkit-animation: tracking-in-contract-bck 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
  animation: tracking-in-contract-bck 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}

.blueGlobal {
  background: #0D47A1 !important;
  color: white !important;
}

@-webkit-keyframes tracking-in-contract-bck {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(400px);
    transform: translateZ(400px);
    opacity: 0;
  }

  40% {
    opacity: 0.6;
  }

  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

@keyframes tracking-in-contract-bck {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(400px);
    transform: translateZ(400px);
    opacity: 0;
  }

  40% {
    opacity: 0.6;
  }

  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}
</style>