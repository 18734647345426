/*################################################################################## 
###### HERINCO                                                                ###### 
###### @author: Oscar Andrés Ochoa Villegas                                   ###### 
###### @date: Enero 2022                                                      ###### 
##################################################################################*/

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Keycloak from 'keycloak-js';
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import axios from "axios";
import JsonCSV from 'vue-json-to-csv'
import ModelSelect from 'vue-search-select/dist/VueSearchSelect.css'

Vue.config.productionTip = false

/**
 * URLS usadas por el front
 */
/*
const urlBaseGateway = 'http://127.0.0.1:8771/';
const urlBaseKeycloak = 'http://127.0.0.1:8080/';
const urlBaseHerinco = 'http://127.0.0.1:8081/';
 */
/* const urlBaseGateway = 'http://192.168.110.52:8771/';
const urlBaseKeycloak = 'http://192.168.110.52:8080/';
const urlBaseHerinco = 'http://192.168.110.52:8081/'; */
 
const urlBaseGateway = 'https://msa.herincohan.com:8771/';
const urlBaseKeycloak = 'https://seguridad.herincohan.com/';
const urlBaseHerinco = 'https://www.herincohan.com/';

const isExternalRoute  = window.location.href.includes("/external");

// Cargar configuración específica para rutas externas
if (isExternalRoute) {
  require('./mainExternal');
} else {
  //prueba sonar
  /**
   * Construcción de la autenticación usando keycloack
   */
  let initOptions = {
    url: urlBaseKeycloak + 'auth',
    realm: 'herinco',
    clientId: 'herinco-client',
    onLoad: 'login-required',
    flow: 'implicit'
  }
   
  /**
   * Inicializa la instancia de KeyCloak y permite la creación de la aplicación VUE luego de la autenticacion
   */
  let keycloak = Keycloak(initOptions);
  keycloak.init({ onLoad: initOptions.onLoad }).then((auth) => {
    if (!auth) {
      window.location.reload();
    } else {
      // Configuracion del store para la autenticacion
      store.commit('updateAuth', { token: keycloak.token, roles: keycloak.tokenParsed.realm_access.roles, username: keycloak.tokenParsed.preferred_username, keycloak: keycloak });
      // URL base de keycloak disponible a niveºl global
      Vue.prototype.$urlBaseHerinco = urlBaseHerinco;
   
      // Configuracion de axios con autencicacion y direccion base de gateway
      const base = axios.create({
        baseURL: urlBaseGateway,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': '*',
          'Access-Control-Allow-Headers': 'Origin, Accept, X-Requested-With, Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token, Access-Control-Request-Method,Access-Control-Request-Headers',
          'Access-Control-Allow-Credentials': true,
          'Content-Type': 'application/json'
        }
      });
   
      Vue.prototype.$http = base;
      Vue.prototype.$http.interceptors.request.use(
        config => {
          let accessToken = keycloak.token;
          if (accessToken) {
            config.headers = Object.assign({ Authorization: `Bearer ${accessToken}` }, config.headers);
          }
          return config;
        },
        error => {
          return Promise.reject(error);
        }
      );
   
    /**
     * Adicion del idEmpresa en la cabecera
     */
    Vue.prototype.$http.interceptors.request.use(
      config => {
        if(localStorage?.idEmpresa) {
          config.headers = Object.assign({ 'idEmpresa': localStorage?.idEmpresa }, config.headers);
        }
        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );
    
      Vue.component('downloadCsv', JsonCSV)
      Vue.component('model-select', ModelSelect)
      new Vue({
        el: '#app',
        router,
        store,
        vuetify,
        render: h => h(App, {})
      });
   
    }
  });
   
  /**
   * Refresca el token ante en el servidor de KeyCloak
   */
  setInterval(() => {
    keycloak.updateToken(70).then((refreshed) => {
      if (refreshed) {
        store.commit('updateAuth', { token: keycloak.token, roles: keycloak.tokenParsed.realm_access.roles, username: keycloak.tokenParsed.preferred_username, keycloak: keycloak });
        //console.log("Token de refresco actualizado")
      }
    }).catch(() => {
      //console.log("Error actualizando token de refresco")
    });
  }, 6000);
   
  /**
   * Instalar servirworker para las notificaciones push con firebase
   */
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/firebase-messaging-sw.js')
      .then(function (registration) {
        console.log('Registration successful, scope is:', registration.scope);
      }).catch(function (err) {
        console.log('Service worker registration failed, error:', err);
      });
  }
}
