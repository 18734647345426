/*################################################################################## 
###### HERINCOHAN                                                             ###### 
###### @author: Diego Alexander Villalba                                      ###### 
###### @date: Octubre 2023                                                    ###### 
##################################################################################*/

const Trade = () => import('../Trade.vue');
const PortafolioVentas = () => import('../PortafolioVentas.vue');
const Clientes = () => import('../Clientes.vue');

import { Role } from "@/router/role.js";


const tradeRoutes = [
  {
    path: 'trade',
    name: 'modules.analytics.trade',
    component: Trade,
    children: [
      {
        path: 'portafolio-ventas',
        name: 'modules.analytics.trade.portafolioDeVentas',
        component: PortafolioVentas,
        meta: { authorize: Role.Analitica_Comercial && Role.Analitica_Comercial_PortafolioDeVentas }
      },
      {
        path: 'clientes',
        name: 'modules.analytics.trade.clientes',
        component: Clientes,
        meta: { authorize: Role.Analitica_Comercial && Role.Analitica_Comercial_Clientes }
      },

    ],
    meta: { authorize: Role.Analitica_Comercial }
  },
];


export default tradeRoutes;