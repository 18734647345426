/*################################################################################## 
###### HERINCOHAN                                                             ###### 
###### @author: John David Vásquez Serna                                     ###### 
###### @date: Febrero 2023                                                    ###### 
##################################################################################*/

const Historys = () => import('../Historys.vue');
const Historial = () => import('../Historial.vue');
import { Role } from "@/router-external/role.js";

const profileRoutes = [
  {
    path: 'historys',
    name: 'external.login.modules.affiliates.historys',
    component: Historys,
    children: [
      {
        path: 'historial',
        name: 'external.login.modules.affiliates.historys.historial',
        component: Historial,
        meta: { authorize: Role.profileRoutes && Role.Afiliados_Solicitud_Historial }
      },
    ],
    meta: { authorize: Role.profileRoutes }
  },

];

export default profileRoutes;