/*################################################################################## 
###### HERINCOHAN                                                             ###### 
###### @author: Diego Alexander Villalba                                      ###### 
###### @date: Octubre 2023                                                    ###### 
##################################################################################*/

import { Role } from "../../../router/role.js";
import planningRoutes from './planning/routes/planning.routes.js';
import tradeRoutes from './trade/routes/trade.routes.js';
import shoppingRoutes from './shopping/routes/shopping.routes.js';
import logisticsRoutes from './logistics/routes/logistics.routes.js';
import pharmaceuticalServiceRoutes from './pharmaceutical-services/routes/pharmaceuticalServices.routes.js';
import billingServiceRoutes from './billing/routes/billing.routes.js';
import boardServiceRoutes from './board/routes/board.routes.js';

const Analytics = () => import('./Analytics.vue');

const analyticsRoutes = [
  {
    path: '/modules/analytics',
    name: 'modules.analytics',
    component: Analytics,
    children: [
      ...planningRoutes,
      ...tradeRoutes,
      ...shoppingRoutes,
      ...logisticsRoutes,
      ...pharmaceuticalServiceRoutes,
      ...billingServiceRoutes,
      ...boardServiceRoutes
    ],
    meta: { authorize: Role.Analitica }
  },
];


export default analyticsRoutes;