/*################################################################################## 
###### HERINCOHAN                                                             ###### 
###### @author: John David Vásquez Serna                                      ###### 
###### @date: Agosto 2024                                                     ###### 
##################################################################################*/

const Dispensation = () => import('../Dispensation.vue')
const ReporteSolicitudesEntregasDomicilio = () => import('../ReporteSolicitudesEntregasDomicilio.vue');

import { Role } from "@/router/role.js";

const dispensationRoutes = [
  {
    path: 'dispensation',
    name: 'modules.reports.dispensation',
    component: Dispensation,
    children: [
      {
        path: 'solicitud-medicamentos',
        name: 'modules.reports.dispensation.solicitudMedicamentos',
        component: ReporteSolicitudesEntregasDomicilio,
        meta: { authorize: Role.dispensationRoutes && Role.Reportes_Dispensation_SolicitudMedicamentos }
      },
    ],
    meta: { authorize: Role.dispensationRoutes }
  },

];

export default dispensationRoutes;