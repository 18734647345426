/*################################################################################## 
###### HERINCOHAN                                                             ###### 
###### @author: Diego Alexander Villalba                                      ###### 
###### @date: Octubre 2023                                                    ###### 
##################################################################################*/

const Homologator = () => import('../Homologator.vue');
const Homologacion = () => import('../Homologacion.vue');
const HistoricoHomologador = () => import('../HistoricoHomologador.vue');
const HistoricoHomologadorVer = () => import('../HistoricoHomologadorVer.vue')
import { Role } from "@/router/role.js";


const homologatorRoutes = [
  {
    path: 'homologator',
    name: 'modules.tools.homologator',
    component: Homologator,
    children: [
      {
        path: 'homologacion',
        name: 'modules.tools.homologator.homologacion',
        component: Homologacion,
        meta: { authorize: Role.Herramientas_Homologador && Role.Herramientas_Homologador_Homologacion }
      },
      {
        path: 'historico-homologacion',
        name: 'modules.tools.homologator.historicoHomologacion',
        component: HistoricoHomologador,
        meta: { authorize: Role.Herramientas_Homologador && Role.Herramientas_Homologador_HistoricoHomologacion }
      },
      {
        path: 'historico-homologacion/ver/:id',
        name: 'modules.tools.homologator.historicoHomologacion.ver',
        component: HistoricoHomologadorVer,
        meta: { authorize: Role.Herramientas_Homologador && Role.Herramientas }
      },

    ],
    meta: { authorize: Role.Herramientas_Homologador }
  },


];


export default homologatorRoutes;