<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: John David Vásquez Serna                                      ###### -->
<!-- ###### @date: Febrero 2024                                                  ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->

<template>
  <v-app>
    <v-main style="padding: 0 0 !important; padding-bottom: 2rem; overflow-y: auto;" class="fill-height d-flex align-center justify-center pr-5">
      <div class="d-flex justify-center">
        <img class="shadow-img" style="width: 200px;" src="@/assets/images/logoCohanMasVital.png"/>
      </div>
      <div class="d-flex justify-center">
        <v-card class="elevation-3">
          <v-card-title class="headline justify-center">Iniciar sesión</v-card-title>
          <v-card-text style="width: 23rem;">
            <validation-observer ref="observer">
              <v-form ref="form" lazy-validation>
                <div class="d-flex justify-center">
                  <validation-provider v-slot="{ errors }" :rules="{ obligatorio: true, }" ref="tiposDeDocumento">
                      <v-autocomplete class="mb-2" id="tipoDocumento" v-model="tipoIdentificacion" :items="listaDeTiposDocumento" outlined  
                          :menu-props="{offsetY:true, maxHeight: 189, maxWidth: 272}" :error-messages="errors" style="width: 17rem" hide-details
                          dense label="Tipo de identificación" @click="listarTiposDeDocumentos()">
                      </v-autocomplete>
                  </validation-provider>
                </div>
                <div class="d-flex justify-center">
                  <validation-provider v-slot="{ errors }" :rules="{ obligatorio: true, numeric: true, identificacion: 6 }" ref="numeroIdentificacion">
                    <v-text-field class="mb-2" id="numeroIdentificacion" v-model="numeroIdentificacion" dense outlined label="Número identificación" 
                      :error-messages="errors" style="width: 17rem" maxlength="15" @keypress="validarLetra" @paste="handlePaste($event)" hide-details>
                    </v-text-field>
                  </validation-provider>
                </div>
                <div class="d-flex justify-center">
                  <validation-provider v-slot="{ errors }" :rules="{ obligatorio: true, numeric: true, min: 6 }" ref="password">
                    <v-text-field class="mb-2" id="password" v-model="password" dense outlined label="Contraseña" @paste.prevent hide-details
                      :error-messages="errors" style="width: 17rem" maxlength="6" @keypress="validarLetra" 
                      @click:append="show1 = !show1" :type="show1 ? 'text' : 'password'" :append-icon="show1 ? 'visibility' : 'visibility_off'">
                    </v-text-field>
                  </validation-provider>
                </div>
                <div class="d-flex justify-center mb-2">
                  <div style="max-width: 300px; width: 81%;">
                    <div class="g-recaptcha" id="captcha" style="transform:scale(0.9); transform-origin:0 0;"></div>
                  </div>
                </div>
                <!-- <div class="d-flex justify-center mb-2">
                  <div class="captcha-div">
                    <span class="captcha-text">{{ captchaValue }}</span>
                  </div>
                  <div>
                    <v-btn class="ms-2" icon color="#0d47a1" @click="generarCaptcha"><v-icon>refresh</v-icon> </v-btn>
                  </div>
                </div> -->
                <!-- <div class="d-flex justify-center">
                  <validation-provider v-slot="{ errors }" :rules="{ obligatorio: true, captchaValido: captchaValue }" ref="captchaInput">
                    <v-text-field class="mb-2" id="captchaInput" v-model="captchaInput" dense outlined label="Ingrese el texto" 
                      :error-messages="errors" style="width: 17rem" @paste.prevent hide-details>
                    </v-text-field>
                  </validation-provider>
                </div> -->
                <div class="d-flex justify-center">
                  <a class="mt-1 letraIniciar" @click="olvidoContrasena">¿Has olvidado tu contraseña?</a>
                </div>
                <div class="mt-2 d-flex justify-center ">
                  <v-btn class="boton-actualizar" depressed @click="ingresar()">
                    Ingresar
                  </v-btn>
                </div>
                <div v-if="datosIncompletos === true">
                  <span class="camposVacios">
                    {{ this.camposVacios }}
                  </span>
                </div>
              </v-form>
            </validation-observer>
          </v-card-text>
        </v-card>
      </div>
      <div class="d-flex justify-center mt-4">
        <span class="letraInfo">¿Aún no tienes cuenta?</span>
      </div>
      <div class="d-flex justify-center mt-0">
        <a class="letraCrear" @click="registrar">Registrarse</a>
      </div>

      <div class="pieDePaginaContainer">
        <!-- Botón para abrir el diálogo -->
        <div class="d-flex justify-center mt-0">
          <span class="pieDePagina" style="font-size: 15px">©</span>
          <span>&nbsp;</span>
          <span class="pieDePagina">{{ new Date().getFullYear() }} COHAN - Todos los derechos reservados.</span>
          <span>&nbsp;</span>
          <a class="pieDePagina" @click="abrirDialogoTerminos">Privacidad</a>
          <PoliticaPrivacidad/>
        </div>
      </div>

      <!-- Contenedor para mostrar los mensajes de error -->
      <div v-if="mostrarNotificacion" :class="['notificacion', tipoNotificacion]">
        <span><v-icon :color="tipoNotificacionColor" :class="iconoNotificacionClase" class="rotate-animation size">{{ iconoNotificacion }}</v-icon></span>
        <span>{{ mensajeNotificacion }}</span>
      </div>
    </v-main>
  </v-app>
</template>

<!-- #################################################################################### -->
<!-- ###### Sección de Scripts                                                     ###### -->
<!-- #################################################################################### -->
<script>
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { required, min, numeric } from 'vee-validate/dist/rules';
import { mapMutations } from 'vuex';
import CryptoJS from "crypto-js";
import config from '../../../config';
import PoliticaPrivacidad from '../../../components/PoliticaPrivacidad.vue';

extend('obligatorio', {
    ...required,
    message: 'Campo obligatorio'
});
extend('min', {
    ...min,
    message: 'Este campo permite mínimo 6 dígitos '
});
extend('identificacion', {
    ...min,
    message: 'Este campo permite mínimo 7 dígitos '
});
extend('numeric', {
    ...numeric,
    message: 'Este campo solo permite números'
});
let captchaValue = '';
extend('captchaValido', {
  validate: (value) => value === captchaValue,
  message: 'Captcha no válido',
});

export default {
  name: 'AffiliatesLogin',
  components: {
    ValidationObserver,
    ValidationProvider,
    PoliticaPrivacidad,
  },

  data() {
    return {
      listaDeTiposDocumento: [],
      rules: {
        agregarRules: [
          v => !!v || "Campo obligatorio",
        ],
      },
      tipoIdentificacion: '',
      numeroIdentificacion: '',
      password: '',
      camposVacios: '',
      datosIncompletos: false,
      captchaValue: '',
      captchaInput: '',
      show1: false,
      mostrarNotificacion: false,
      mensajeNotificacion: "",
      tipoNotificacion: "",
      tipoNotificacionColor: "",
      iconoNotificacion: "",
      iconoNotificacionClase: "",
    };
  },
  created() {
    this.listarTiposDeDocumentos();
  },
  mounted() {
    this.generarCaptcha();
    
    window.onloadCallback = function() {
      // Renderizar el widget reCAPTCHA en el elemento con el ID 'captcha'
      window.grecaptcha.render('captcha',{
        'sitekey' : process.env.VUE_APP_GCAPTCHA_KEY, // Clave del sitio proporcionada por Google reCAPTCHA
        'badge': 'inline',
      });
    };
    // Crea un elemento de script para cargar el script de la API reCAPTCHA de Google
    const script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit'; // URL del script de la API reCAPTCHA
    document.body.appendChild(script);
  },
  watch: {
    'captchaValue': function (captcha) {
      captchaValue = captcha
    },
  },
  methods: {
    ...mapMutations([]),
    /**
     * Genera un nuevo valor para el captcha.
     * 
     * Genera un captcha aleatorio con una longitud de 6 caracteres, utilizando caracteres alfanuméricos.
     * El valor generado se asigna a la variable captchaValue del componente.
     */
    generarCaptcha() {
      const caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let nuevoCaptcha = '';
      for (let i = 0; i < 6; i++) {
        nuevoCaptcha += caracteres.charAt(Math.floor(Math.random() * caracteres.length));
      }
      this.captchaValue = nuevoCaptcha;
    },
    /**
     * Método usado para abrir el dialogo que contiene las politicas de privacidad.
     */
    abrirDialogoTerminos() {
      this.$root.$emit('abrirDialogo');
    },
    /**
     * Recupera una lista de tipos de documentos desde el servidor y la almacena en la propiedad
     * 'listaDeTiposDocumento' del componente.
     * @throws {Error} Si ocurre un error durante la solicitud al servidor.
     */
    listarTiposDeDocumentos() {
      this.listaDeTiposDocumento = [];
      this.$http.get(`msa-external/public/tipo-documento/list`)
      .then((response) => {
        this.listaDeTiposDocumento = response.data.map(item => ({
            text: `${item.descripcion}`,
            value: item.tipoDocumento,
            tipoDocumento: item.tipoDocumento,
          }))
          this.tipoIdentificacion = "CC";
      }).catch((error) => {
          console.log(error);
          this.manejarError(error);
      })
    },
    /**
     * Valida que la tecla presionada sea un número.
     * Evita la entrada de caracteres no numéricos.
     * @param {Event} event - Evento de tecla presionada.
     */
    validarLetra(event) {
      const teclaPresionada = event.key
      if (teclaPresionada.match(/[^0-9]/)) {
        event.preventDefault();
      }
    },
    /**
     * Realiza el proceso de inicio de sesión del afiliado.
     * 
     * Si todos los campos están completos y no hay errores de validación, encripta la contraseña 
     * utilizando una clave secreta y envía una solicitud POST al servidor para realizar el inicio de sesión.
     * 
     * Si el inicio de sesión es exitoso, guarda el token de autenticación y el número de identificación en el almacenamiento local del navegador.
     * 
     * Si ocurre un error durante el inicio de sesión, muestra un mensaje de error adecuado en función de la respuesta del servidor.
     */
    async ingresar() {
      const token = await window.grecaptcha.getResponse();
      if (!token) {
        this.camposVacios = 'Por favor, complete todos los campos.';
        this.datosIncompletos = true;
        return;
      }
      const tI = this.tipoIdentificacion;
      const nI = this.numeroIdentificacion;
      const pw = this.password;
      // const cI = this.captchaInput;
      if (tI === '' || nI === '' || pw ==='') {
        this.camposVacios = 'Por favor, complete todos los campos.';
        this.datosIncompletos = true;
        return;
      }
      const tiposDeDocumentoErrors = this.$refs.tiposDeDocumento.errors;
      const numeroIdentificacionErrors = this.$refs.numeroIdentificacion.errors;
      const passwordErrors = this.$refs.password.errors;
      // const captchaInputErrors = this.$refs.captchaInput.errors;
      if (tiposDeDocumentoErrors.length > 0 || numeroIdentificacionErrors.length > 0  || passwordErrors.length > 0) {
        this.camposVacios = 'Por favor, complete todos los campos correctamente.';
        this.datosIncompletos = true;
        return;
      }
      this.camposVacios = '';
      this.datosIncompletos = false;
      const password = this.password;
      const secretKey =  config.secretKey;
      const resultadoEncriptacion = await this.encriptarPassword(password, secretKey);
      const datosAfiliado = {
        idEmpresa: 1,
        tipoDocumento: this.tipoIdentificacion,
        numeroDocumento: this.numeroIdentificacion,
        password: resultadoEncriptacion.encryptedPassword,
      }
      this.$http.post(`msa-external/public/login`, datosAfiliado)
      .then((response) => {
        if (response.data.status === 0) {
          this.camposVacios = response.data.message;
          this.datosIncompletos = true;
        } else {
          this.camposVacios = '';
          this.datosIncompletos = false;
          this.$store.dispatch('handleAuthentication', response.data.token);
          this.$store.dispatch('handleRefresh', response.data.refreshToken);
          this.$store.commit('setTipoIdentificacion', this.tipoIdentificacion);
          this.$store.commit('setNumeroIdentificacion', this.numeroIdentificacion);
          this.$router.push('/external/login/modules/affiliates/requests/entrega');
        }
      }).catch((error) => {
        console.log(error);
        if (error.response && error.response.data && error.response.data.message) {
          const errorMessage = error.response.data.message;
          const cleanMessage = errorMessage.replace(/^[^:]*:\s*/, '');
          const message = cleanMessage.replace(/:.*$/, '');
          if (message.includes('RESTEASY')) {
            this.manejarError(error);
          } else {
            this.camposVacios = message;
            this.datosIncompletos = true;
          }
        } else {
          this.manejarError(error);
        }
      })
    },
    /**
     * Método para encriptar una contraseña utilizando el algoritmo AES.
     * 
     * @param {string} password - La contraseña a encriptar.
     * @param {string} claveSecreta - La clave secreta utilizada para la encriptación.
     * @returns {object} Un objeto que contiene el vector de inicialización (iv) y la contraseña encriptada.
     * @throws {Error} Error si ocurre algún problema durante la encriptación.
     */
    async encriptarPassword(password, claveSecreta) {
      try {
        const clave = CryptoJS.enc.Utf8.parse(claveSecreta);

        const iv = CryptoJS.lib.WordArray.random(16);
        
        const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(password), clave, {
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
        });

        const encryptedBase64 = CryptoJS.enc.Base64.stringify(iv.concat(encrypted.ciphertext));
        
        return { iv: iv.toString(CryptoJS.enc.Base64), encryptedPassword: encryptedBase64 };
      } catch (error) {
        throw new Error("Error al encriptar la contraseña: " + error.message);
      }
    },
    /**
     * Redirige a la página de validación de afiliado con la opción de validar contraseña o registrarse.
     * 
     * @param {boolean} contrasena - Indica si se va a validar la contraseña.
     * @param {boolean} registrarse - Indica si se va a registrar un nuevo afiliado.
     */
    redirectToValidarAfiliado(contrasena, registrarse) {
      window.location.href = `/#/external/validarAfiliado?contrasena=${contrasena}&registrarse=${registrarse}`;
      window.location.reload();
    },
    /**
     * Redirige a la página de validación de afiliado para recuperar la contraseña olvidada.
     */
    olvidoContrasena() {
      const contrasena = true;
      const registrarse = false;
      this.redirectToValidarAfiliado(contrasena, registrarse);
    },
    /**
     * Redirige a la página de validación de afiliado para el registro de un nuevo afiliado.
     */
    registrar() {
      const contrasena = false;
      const registrarse = true;
      this.redirectToValidarAfiliado(contrasena, registrarse);
    },
    /**
     * Maneja el evento de pegado en un campo de entrada, permitiendo solo valores numéricos.
     * @param {Event} event El evento de pegado.
     */
    handlePaste(event) {
      event.preventDefault();
      const clipboardData = event.clipboardData || window.clipboardData;
      const pastedData = clipboardData.getData('text');
      if (!/^\d+$/.test(pastedData)) {
        return;
      }
      this.numeroIdentificacion = pastedData;
    },
    /**
   * Muestra una notificación global en el componente.
   *
   * @param {string} mensaje - Mensaje que se mostrará en la notificación.
   * @param {string} tipo - Tipo de la notificación (por defecto, "error").
   * @param {string} icono - Icono de la notificación (por defecto, "highlight_off").
   */
    mostrarNotificacionGlobal(mensaje, tipo, icono) {
      this.mostrarNotificacion = true;
      this.tipoNotificacion = tipo || "advertencia"; // Tipo predeterminado es "error"
      this.tipoNotificacionColor = this.obtenerColorNotificacion(this.tipoNotificacion);
      this.iconoNotificacion = icono || "highlight_off"; // Icono predeterminado es "highlight_off"
      this.mensajeNotificacion = mensaje;
      this.iconoNotificacionClase = this.obtenerClaseIcono(this.tipoNotificacion);

      // Cierra la notificación después de 5 segundos
      setTimeout(() => {
        this.cerrarNotificacion();
      }, 5000); 
    },
    /**
     * Cierra la notificación
     */
    cerrarNotificacion() {
      this.mostrarNotificacion = false;
      this.mensajeNotificacion = "";
      this.tipoNotificacion = "";
    },
    /**
     * Obtiene el color correspondiente al tipo de notificación.
     * 
     * @param {string} tipo - Tipo de notificación.
     * @returns {string} - Color de la notificación.
     */
    obtenerColorNotificacion(tipo) {
      switch (tipo) {
        case "advertencia":
          return "#f80505";
        default:
          return "#f80505"; 
      }
    },
    /**
     * Obtiene la clase de icono correspondiente al tipo de notificación.
     * 
     * @param {*} tipo  - Tipo de notificación.
     * @returns {string} - Clase de icono.
     */
    obtenerClaseIcono(tipo) {
      switch (tipo) {
        case "advertencia":
          return "advertencia-icon";
        default:
          return "advertencia-icon";
      }
    },
    /**
     * Maneja errores y muestra notificaciones correspondientes.
     * 
     * @param {*} error - Objeto de error.
     */
    manejarError(error) {
      if (error.response) {
        if (error.response.status === 503 || error.response.status === 500) {
          this.mostrarNotificacionGlobal("Error en la red, inténtalo más tarde.", "advertencia", "highlight_off" );
        } else if (error.response.status === 401) {
          this.mostrarNotificacionGlobal("Error en los datos, verifique los datos.", "advertencia", "highlight_off" );
        } else {
          this.mostrarNotificacionGlobal("Error inesperado, contacta con el administrador.", "advertencia", "highlight_off");
        }
      } else {
        this.mostrarNotificacionGlobal("Error inesperado, contacta con el administrador.", "advertencia", "highlight_off");
      }
    },
  },
  
};
</script>

<!-- #################################################################################### -->
<!-- ###### Sección de Estilos                                                     ###### -->
<!-- #################################################################################### -->
<style scoped>
.letraIniciar {
  font-size: small;
  color:#0d47a1;
}
.letraInfo, .letraCrear {
  font-size: medium;
}
.letraInfo {
  color:black;
}
.letraCrear {
  color:#0d47a1;
}
.boton-actualizar {
  background-color: #0d47a1 !important;
  color: #fff;
  border-radius: 18px;
}
.shadow-img {
  filter: drop-shadow(0px 3px 3px rgba(63, 63, 65, 0.7));
}
.camposVacios {
  color: #f80505;
  display: flex;
  justify-content: center;
  font-size: small;
}
.pieDePaginaContainer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #0d47a1; 
  height: 1.5rem; 
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 10px -5px 10px rgba(0, 0, 0, 0.3);
}
.pieDePagina {
  color: white;
  font-size: small;
  margin-top: 0.3rem;
}
.captcha-div {
  border: 1px solid #000000;
  padding: 0.5rem;
  margin-bottom: 0rem;
  width: 12rem;
  background: linear-gradient(45deg, #f0f0f0 25%, transparent 25%, transparent 75%, #f0f0f0 75%, #f0f0f0),
                linear-gradient(45deg, #f0f0f0 25%, transparent 25%, transparent 75%, #f0f0f0 75%, #f0f0f0);
  background-size: 20px 20px;
}
.captcha-text {
  display: flex;
  justify-content: center;
  font-family: 'Arial', sans-serif;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 5px;
  transform: rotate(-2deg) translateY(1px);
  text-shadow: 2px 2px 7px rgba(0, 0, 0, 0.3);
}
.notificacion {
  position: fixed;
  top: 50px;
  right: 20px;
  padding: 15px;
  border-radius: 5px;
  z-index: 9999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: opacity 0.5s ease-in-out;
}
.advertencia {
  background-color: #ffffffd7 !important;
  color: #f80505;
}
.notificacion span:last-child {
  cursor: pointer;
  margin-right: 10px;
  padding-top: 3px;
}
.advertencia-icon {
  color: #f80505;
}
.rotate-animation {
  animation: rotate 1s ease-in-out; 
}
@keyframes rotate {
  0% {
    transform: rotateX(180deg);
  }

  50% {
    transform: rotateX(0deg);
  }

  100% {
    transform: rotateX(180deg);
  }
}
.g-recaptcha-badge .rc-audiochallenge-button{
  visibility: hidden !important;
}
</style>
