/*################################################################################## 
###### HERINCOHAN                                                             ###### 
###### @author: Diego Alexander Villalba                                      ###### 
###### @date: Octubre 2023                                                    ###### 
##################################################################################*/

const Billing = () => import('../Billing.vue');
const NotasCredito = () => import('../NotasCredito.vue');
const Devoluciones = () => import('../Devoluciones.vue');
const Facturacion = () => import('../Facturacion.vue');

import { Role } from "@/router/role.js";


const billingServiceRoutes = [
  {
    path: 'billing',
    name: 'modules.analytics.billing',
    component: Billing,
    children: [
      {
        path: 'notas-credito',
        name: 'modules.analytics.billing.notasCredito',
        component: NotasCredito,
        meta: { authorize: Role.Analitica_Facturacion && Role.Analitica_Facturacion_NotasCredito }
      },
      {
        path: 'devoluciones',
        name: 'modules.analytics.billing.devoluciones',
        component: Devoluciones,
        meta: { authorize: Role.Analitica_Facturacion && Role.Analitica_Facturacion_Devoluciones }
      },
      {
        path: 'facturacion',
        name: 'modules.analytics.billing.facturacion',
        component: Facturacion,
        meta: { authorize: Role.Analitica_Facturacion && Role.Analitica_Facturacion_Facturacion }
      },
    ],
    meta: { authorize: Role.Analitica_Facturacion }
  },
];


export default billingServiceRoutes;