/*################################################################################## 
###### HERINCOHAN                                                             ###### 
###### @author: Diego Alexander Villalba                                      ###### 
###### @date: Octubre 2023                                                    ###### 
##################################################################################*/

const Help = () => import('./Help.vue');


const helpRoutes = [
  {
    path: '/modules/Help',
    name: 'modules.help',
    component: Help,
    children: [
    ],
    meta: {}
  },
];


export default helpRoutes;