/*################################################################################## 
###### HERINCOHAN                                                             ###### 
###### @author: Diego Alexander Villalba                                      ###### 
###### @date: Octubre 2023                                                    ###### 
##################################################################################*/

const Planning = () => import('../Planning.vue');
const NotasTecnicas = () => import('../NotasTecnicas.vue');
const Consumo = () => import('../Consumo.vue');
const DispensacionFaltantes = () => import('../DispensacionFaltantes.vue');
const ValoresDispensacion = () => import('../ValoresDispensacion.vue');
const DispensacionNuevaEps = () => import('../DispensacionNuevaEps.vue');
const DispensacionSavia = () => import('../DispensacionSavia.vue');

import { Role } from "@/router/role.js";


const planningRoutes = [
  {
    path: 'planning',
    name: 'modules.analytics.planning',
    component: Planning,
    children: [
      {
        path: 'notas-tecnicas',
        name: 'modules.analytics.planning.notasTecnicas',
        component: NotasTecnicas,
        meta: { authorize: Role.Analitica_Planeacion && Role.Analitica_Planeacion_NotasTecnicas }
      },
      {
        path: 'consumo',
        name: 'modules.analytics.planning.consumo',
        component: Consumo,
        meta: { authorize: Role.Analitica_Planeacion && Role.Analitica_Planeacion_Consumo }
      },
      {
        path: 'dispansaciones-faltantes',
        name: 'modules.analytics.planning.dispensacionFaltantes',
        component: DispensacionFaltantes,
        meta: { authorize: Role.Analitica_Planeacion && Role.Analitica_Planeacion_DispensacionesFaltantes }
      },
      {
        path: 'valores-dispensacion',
        name: 'modules.analytics.planning.valoresDispensacion',
        component: ValoresDispensacion,
        meta: { authorize: Role.Analitica_Planeacion && Role.Analitica_Planeación_ValoresDeDispensacion }
      },
      {
        path: 'dispensacion-nueva-eps',
        name: 'modules.analytics.planning.dispensacionNuevaEps',
        component: DispensacionNuevaEps,
        meta: { authorize: Role.Analitica_Planeacion && Role.Analitica_Planeacion_DispensacionNuevaEPS }
      },
      {
        path: 'dispensacion-savia',
        name: 'modules.analytics.planning.dispensacionSavia',
        component: DispensacionSavia,
        meta: { authorize: Role.Analitica_Planeacion && Role.Analitica_Planeacion_DispensacionSaviaSalud }
      },
    ],
    meta: { authorize: Role.Analitica_Planeacion }
  },
];


export default planningRoutes;