/*################################################################################## 
###### HERINCOHAN                                                             ###### 
###### @author: John David Vásquez Serna                                     ###### 
###### @date: Febrero 2023                                                    ###### 
##################################################################################*/

const Requests = () => import('../Requests.vue');
const Entrega = () => import('../Entrega.vue');
import { Role } from "@/router-external/role.js";

const profileRoutes = [
  {
    path: 'requests',
    name: 'external.login.modules.affiliates.requests',
    component: Requests,
    children: [
      
      {
        path: 'entrega',
        name: 'external.login.modules.affiliates.requests.entrega',
        component: Entrega,
        meta: { authorize: Role.profileRoutes && Role.Afiliados_Solicitud_Entrega }
      },
    ],
    meta: { authorize: Role.profileRoutes }
  },

];

export default profileRoutes;