/*################################################################################## 
###### HERINCOHAN                                                             ###### 
###### @author: Diego Alexander Villalba                                      ###### 
###### @date: Octubre 2023                                                    ###### 
##################################################################################*/

import { Role } from "@/router/role.js";
import autoimmuneMeshRoutes from './autoimmune-mesh/routes/autoimmuneMesh.routes.js';
import serviceRequestRoutes from "./service-request/routes/serviceRequest.routes.js";

const validatorMeshMain = () => import('./validatorMeshMain.vue');

const validatorMeshMainRoutes = [
  {
    path: '/modules/validator-mesh',
    name: 'modules.validatorMeshMain',
    component: validatorMeshMain,
    children: [
      ...autoimmuneMeshRoutes,
      ...serviceRequestRoutes
    ],
    meta: { authorize: Role.MallaValidadora }
  },
];


export default validatorMeshMainRoutes;