/*################################################################################## 
###### HERINCO                                                                ###### 
###### @author: Oscar Andrés Ochoa Villegas                                   ###### 
###### @date: Enero 2022                                                      ###### 
##################################################################################*/

import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

/**
 * Construccion del tema principal y colores de Vuetify aplicables a todo el sistema
 */
export default new Vuetify({
  theme: {
      options: {
        customProperties: true,
      },
    themes: {
      light: {
        primary: '#0D47A1',
        secondary: '#0a5cb8',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'
      },
    },
  },
  icons: {
    iconfont: 'md',
  },
});
