<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: Oscar Andrés Ochoa Villegas                                   ###### -->
<!-- ###### @date: Enero 2022                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <v-app>
    <!--Componente Header: encabezado - Notificaciones - Perfil -->
    <Header v-bind:user='username' v-bind:rol='userRoles' v-bind:logout="logout" />

    <!-- Menu principal de navegacion -->
    <v-navigation-drawer class="mt-13 sideBar" v-model="drawer" :mini-variant="mini" permanent app>
      <v-list v-if="this.enterprise.code != ''" dense>
        <v-tooltip v-for="{ name, link, icon, rol } in itemsMenu" :key="name" color="blue darken-4" right>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item class="py-3" v-if="userRoles.includes(rol)" v-bind="attrs" v-on="on" :to="link">
              <v-icon class="iconMenu">{{ icon }}</v-icon>
            </v-list-item>
          </template>
          <span>{{ name }}</span>
        </v-tooltip>
      </v-list>
    </v-navigation-drawer>

    <!-- Seccion para cargar el contenido de la navegación -->
    <v-main fluid>
      <v-slide-y-transition mode="out-in">
        <router-view></router-view>
      </v-slide-y-transition>
    </v-main>
    <!-- Notificaciones con mensajes de error, advertencia e informativos -->
    <v-snackbar v-model="notify.visible" :color="notify.color" multi-line top :timeout="notify.time">
      {{ notify.message }}
    </v-snackbar>

    <!-- Sección para notificaciones de sistema ocupado -->
    <v-dialog v-model="busy.visible" max-width="400" persistent>
      <v-card>
        <v-card-title class="blueGlobal" dark card>
          {{ busy.title }}
        </v-card-title>
        <v-card-text class="subheading">
          {{ busy.message }}
        </v-card-text>
        <v-card-text>
          <v-progress-linear :indeterminate="true" class="blueGlobal"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Pie de página -->
    <v-footer dark padless dense color="primary" class="footer">
      <v-layout justify-center>
        <span>HERINCOHAN - Todos los derechos reservados @ Copyright {{ new Date().getFullYear() }}</span>
      </v-layout>
    </v-footer>
  </v-app>
</template>

<!-- #################################################################################### -->
<!-- ###### Sección de Scripts                                                     ###### -->
<!-- #################################################################################### -->
<script>
import { mapMutations, mapState } from "vuex";
import { Role } from "./router/role.js";
import firebase from "firebase/compat/app";
import "firebase/compat/messaging";
import "firebase/compat/auth";
import Header from "@/components/Header.vue";


const firebaseConfig = {
  apiKey: "AIzaSyC0pLKX6JyrTptDxSJtHscPU6DK31cmQZE",
  authDomain: "herincohan.firebaseapp.com",
  projectId: "herincohan",
  storageBucket: "herincohan.appspot.com",
  messagingSenderId: "404665344405",
  appId: "1:404665344405:web:ee6baf736cfce4a6d0169c"
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
const vapidKey = "BOeSOODiseQxVb5vrx0OFelfmQuRmlhUlAnP-weMgewkO29ilwNyN1tgX1H4oJFUC6Nbg2i18sW_sRxbBCmZtO4";

export default {
  name: "App",
  components: {
    Header,
  },

  data() {
    return {
      username: "",
      userRoles: {},
      roles: Role,
      drawer: true,
      mini: true,
      mensaje: 1,
      rightMenu: true,
      itemsMenu: [
        {
          name: 'Configuraciones',
          link: { name: 'modules.settings' },
          icon: 'settings',
          rol: Role.Configuraciones
        },
        {
          name: 'Servicios Farmacéuticos',
          link: { name: 'modules.pharmaceuticalServicesMain' },
          icon: 'medical_services',
          rol: Role.ServiciosFarmaceuticos
        },
        {
          name: 'Herramientas',
          link: { name: 'modules.tools' },
          icon: 'handyman',
          rol: Role.Herramientas
        },
        {
          name: 'Analítica',
          link: { name: 'modules.analytics' },
          icon: 'equalizer',
          rol: Role.Analitica
        },
        {
          name: 'Logística',
          link: { name: 'modules.logistics' },
          icon: 'location_searching',
          rol: Role.Logistica
        },
        {
          name: 'Reportes',
          link: { name: 'modules.reports' },
          icon: 'description',
          rol: Role.Reportes
        },
        {
          name: 'Malla Validadora Ruta Autoinmune',
          link: { name: 'modules.validatorMeshMain' },
          icon: 'beenhere',
          rol: Role.MallaValidadora
        }
      ],
      timeoutDuration: 15 * 60 * 1000,
      timeout: null
    };
  },
  created() {
    let site24x7Script = document.createElement("script");
    site24x7Script.setAttribute("src", "https://www.herincohan.com/site24x7.js");
    document.head.appendChild(site24x7Script);
    this.userRoles = this.auth.roles;
    this.username = this.auth.username;
    if (Notification.permission === "granted") {
      app
        .messaging()
        .getToken({ vapidKey: vapidKey })
        .then((currentToken) => {
          if (currentToken) {
            const usuario = {
              usuario: this.username,
              token: currentToken
            };
            this.$http
              .post("msa-pharmaceutical-services/editToken", usuario)
              .then((result) => {
                console.log(result);
              }).catch((error) => {
                console.log(error);
              });
          }
        })
        .catch((err) => {
          console.log("An error occurred while retrieving token. ", err);
        });
    }
    else if (Notification.permission !== "denied") {
      Notification.requestPermission().then(function (permission) {
        if (permission === "granted") {
          //this.obtenerToken();
        }
      });
    }
  },
  computed: {
    ...mapState(["auth", "notify", "busy", "enterprise"]),
  },
  methods: {
    ...mapMutations([
      "updateAuth",
      "hideNotify",
      "showSuccess",
      "showBusy",
      "hideBusy",

    ]),
    logout() {
      var logoutOptions = { redirectUri: this.$urlBaseHerinco };
      this.auth.keycloak
        .logout(logoutOptions)
        .then((success) => {
          this.updateAuth({});
          console.log("--> log: logout success ", success);
        })
        .catch((error) => {
          console.log("--> log: logout error ", error);
        });
    },
    startInactivityTimer() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.logout();
      }, this.timeoutDuration);
    },
    resetInactivityTimer() {
      this.startInactivityTimer();
    }
  },
  mounted() {
    this.startInactivityTimer();
    window.addEventListener('mousemove', this.resetInactivityTimer);
    window.addEventListener('keydown', this.resetInactivityTimer);
    window.addEventListener('scroll', this.resetInactivityTimer);
  },
  beforeDestroy() {
    window.removeEventListener('mousemove', this.resetInactivityTimer);
    window.removeEventListener('keydown', this.resetInactivityTimer);
    window.removeEventListener('scroll', this.resetInactivityTimer);

    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }
};
</script>

<!-- #################################################################################### -->
<!-- ###### Sección de Estilos                                                     ###### -->
<!-- #################################################################################### -->
<style scoped>
.logo {
  height: 25px;
  padding-left: 32px;
  padding-right: 32px;
}

.v-list-item--active {
  color: #0D47A1 !important;
}

.iconMenu:hover {
  color: #0D47A1 !important;
}

.v-list {
  padding: 0px;
}

.footer {
  font-size: 11px;
  position: fixed !important;
  bottom: 0;
  width: 100%;
  z-index: 100;
}

.blueGlobal {
  background: #0D47A1 !important;
  color: white !important;
}

/* .blueTextGlobal {
  color: #005296 !important;
} */

.container {
  max-width: 100% !important;
}

.sideBar {
  z-index: 100 !important;
}
</style>