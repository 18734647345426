/*################################################################################## 
###### HERINCOHAN                                                             ###### 
###### @author: Diego Alexander Villalba                                      ###### 
###### @date: Octubre 2023                                                    ###### 
##################################################################################*/

const AutoimmuneMesh = () => import('../AutoimmuneMesh.vue');
const DocumentsCargarMalla = () => import('../DocumentsCargarMalla.vue');
const DocumentsGenerarArchivoPlano = () => import('../DocumentsGenerarArchivoPlano');
import { Role } from "@/router/role.js";

const autoimmuneMeshRoutes = [
  {
    path: 'autoimmune-mesh',
    name: 'modules.validatorMeshMain.autoimmuneMesh',
    component: AutoimmuneMesh,
    children: [
      {
        path: 'cargar-malla',
        name: 'modules.validatorMeshMain.AutoimmunesMesh.cargarMalla',
        component: DocumentsCargarMalla,
        meta: { authorize: Role.ServiciosFarmaceuticos_MallaAutoinmunes && Role.ServiciosFarmaceuticos_MallaAutoinmunes_CargarMalla }
      },
      {
        path: 'generar-archivo-plano',
        name: 'modules.validatorMeshMain.AutoimmunesMesh.generarArchivoPlano',
        component: DocumentsGenerarArchivoPlano,
        meta: { authorize: Role.ServiciosFarmaceuticos_MallaAutoinmunes && Role.ServiciosFarmaceuticos_MallaAutoinmunes_CargarMalla }
      }
    ],
    meta: { authorize: Role.ServiciosFarmaceuticos_MallaAutoinmunes }
  },
];


export default autoimmuneMeshRoutes;