/*################################################################################## 
###### HERINCOHAN                                                             ###### 
###### @author: Estefania Villada Chavarria                                   ###### 
###### @date: Abril 2024                                                      ###### 
##################################################################################*/

const Board = () => import('../Board.vue');
const InformesBI = () => import('../InformesBI.vue')

import { Role } from "@/router/role.js";

const boardServiceRoutes = [
  {
    path: 'board',
    name: 'modules.analytics.board',
    component: Board,
    children: [
      {
        path: 'informes-bi',
        name: 'modules.analytics.board.informesBI',
        component: InformesBI,
        meta: { authorize: Role.Analitica_Tableros_InformesBI }
      },
    ],
    meta: { authorize: Role.Analitica_Tableros }
  },
];

export default boardServiceRoutes;