/*################################################################################## 
###### HERINCOHAN                                                             ###### 
###### @author: Diego Alexander Villalba                                      ###### 
###### @date: Octubre 2023                                                    ###### 
##################################################################################*/

const PharmaceutilcalService = () => import('../PharmaceuticalServices.vue');
const Usuarios = () => import('../Usuarios.vue');
const Medios = () => import('../Medios.vue');
const MotivosIntrahopitalario = () => import('../MotivosIntrahopitalario.vue');
const HomologacionGomedisys = () => import('../HomologacionGomedisys.vue');
const Afiliados = () => import('../Afiliados.vue');
const Restricciones = () => import('../Restricciones.vue')
import { Role } from "@/router/role.js";


const pharmaceutilcalServiceRoutes = [
  {
    path: 'pharmaceutilcal-service',
    name: 'modules.settings.pharmaceutilcalService',
    component: PharmaceutilcalService,
    children: [
      {
        path: 'roles',
        name: 'modules.settings.pharmaceutilcalService.roles',
        component: Usuarios,
        meta: { authorize: Role.Configuraciones_ServiciosFarmaceuticos && Role.Configuraciones_ServiciosFarmaceuticos_Roles }
      },
      {
        path: 'medios',
        name: 'modules.settings.pharmaceutilcalService.medios',
        component: Medios,
        meta: { authorize: Role.Configuraciones_ServiciosFarmaceuticos && Role.Configuraciones_ServiciosFarmaceuticos_Medios }
      },
      {
        path: 'motivos-intraHospitalario',
        name: 'modules.settings.pharmaceutilcalService.motivosIntraHospitalario',
        component: MotivosIntrahopitalario,
        meta: { authorize: Role.Configuraciones_ServiciosFarmaceuticos && Role.Configuraciones_ServiciosFarmaceuticos_MotivosIntrahospitalario }
      },
      {
        path: 'homologacion',
        name: 'modules.settings.pharmaceutilcalService.homologacion',
        component: HomologacionGomedisys,
        meta: { authorize: Role.Configuraciones_ServiciosFarmaceuticos && Role.Configuraciones_ServiciosFarmaceuticos_Homologacion }
      },
      {
        path: 'afiliados',
        name: 'modules.settings.pharmaceutilcalService.afiliados',
        component: Afiliados,
        meta: { authorize: Role.Configuraciones_ServiciosFarmaceuticos && Role.Configuraciones_ServiciosFarmaceuticos_Afiliados }
      },
      {
        path: 'restricciones',
        name: 'modules.settings.pharmaceutilcalService.restricciones',
        component: Restricciones,
        meta: { authorize: Role.Configuraciones_ServiciosFarmaceuticos && Role.Configuraciones_ServiciosFarmaceuticos_Restricciones }
      },
    ],
    meta: { authorize: Role.Configuraciones_ServiciosFarmaceuticos }
  },
];


export default pharmaceutilcalServiceRoutes;