/*################################################################################## 
###### HERINCOHAN                                                             ###### 
###### @author: Diego Alexander Villalba                                      ###### 
###### @date: Octubre 2023                                                    ###### 
##################################################################################*/

const Profile = () => import('./Profile.vue');


const profileRoutes = [
  {
    path: '/modules/profile',
    name: 'modules.profile',
    component: Profile,
    children: [
    ],
    meta: {}
  },
];


export default profileRoutes;