/*################################################################################## 
###### HERINCOHAN                                                             ###### 
###### @author: Diego Alexander Villalba                                      ###### 
###### @date: Octubre 2023                                                    ###### 
##################################################################################*/

const ServiceRequestMain = () => import('../ServiceRequestMain.vue');
const DocumentsFormulario = () => import('../DocumentsFormulario.vue');
const CargarRipsFormulario = () => import('../CargarRipsFormulario.vue');
import { Role } from "@/router/role.js";

const serviceRequestRoutes = [
  {
    path: 'service-request',
    name: 'modules.validatorMesh.serviceRequest',
    component: ServiceRequestMain,
    children: [
      {
        path: 'formulario-solicitud',
        name: 'modules.validatorMeshMain.serviceRequest.formularioSolicitud',
               component: DocumentsFormulario, 
        meta: { authorize: Role.MallaValidadora_SolicitudServicio && Role.MallaValidadora_SolicitudServicio_FormularioSolicitud }
      },
      {
        path: 'cargar-rips',
        name: 'modules.validatorMeshMain.serviceRequest.cargarRips',
               component: CargarRipsFormulario, 
        meta: { authorize: Role.MallaValidadora_SolicitudServicio && Role.MallaValidadora_SolicitudServicio_FormularioSolicitud }
      }
    ],
    meta: { authorize: Role.MallaValidadora_SolicitudServicio }
  },
];


export default serviceRequestRoutes;