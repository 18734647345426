/*################################################################################## 
###### HERINCOHAN                                                             ###### 
###### @author: Diego Alexander Villalba                                      ###### 
###### @date: Octubre 2023                                                    ###### 
##################################################################################*/

const Logistics = () => import('../Logistics.vue');
const PedidosTraslados = () => import('../PedidosTraslados.vue');
const Productividad = () => import('../Productividad.vue');

import { Role } from "@/router/role.js";


const logisticsRoutes = [
  {
    path: 'logistics',
    name: 'modules.analytics.logistics',
    component: Logistics,
    children: [
      {
        path: 'pedidos-traslados',
        name: 'modules.analytics.logistics.pedidosTraslados',
        component: PedidosTraslados,
        meta: { authorize: Role.Analitica_Logistica && Role.Analitica_Logistica_PedidosTraslados }
      },
      {
        path: 'productividad',
        name: 'modules.analytics.logistics.productividad',
        component: Productividad,
        meta: { authorize: Role.Analitica_Logistica && Role.Analitica_Logistica_Productividad }
      },

    ],
    meta: { authorize: Role.Analitica_Logistica }
  },
];


export default logisticsRoutes;