/*##################################################################################
###### HERINCOHAN                                                             ######
###### @author: John David Vásquez Serna                                      ######
###### @date: Febrero 2023                                                    ######
####################################################################################*/

import { Role } from "@/router-external/role.js";
import historysRoutes from './historys/routes/historys.routes.js';
import requestsRoutes from './requests/routes/requests.routes.js';
const Affiliates = () => import('./Affiliates.vue');

const affiliatesRoutes = [
  {
    path: '/external/login/modules/affiliates',
    name: 'external.login.modules.affiliates',
    component: Affiliates,
    children: [
      ...historysRoutes,
      ...requestsRoutes
    ],
    meta: { authorize: Role.Afiliados }
  },
];

export default affiliatesRoutes;