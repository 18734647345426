/*################################################################################## 
###### HERINCO                                                                ###### 
###### @author: Oscar Andrés Ochoa Villegas                                   ###### 
###### @date: Enero 2022                                                      ###### 
##################################################################################*/

import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'

Vue.use(Vuex)

const vuexLocalStorage = new VuexPersist({
  key: 'vuex',
  storage: window.localStorage
})

/** 
 * Almacenamiento principal usado por la aplicacion y el cual agrega almacenamientos secundarios como el de sesion
 */
export default new Vuex.Store({
  state: {
    auth: {
      token: null,
      roles: '',
      username: '',
      keycloak: ''
    },
    notify: {
      visible: false,
      message: '',
      color: 'info',
      time: 5000
    },
    busy: {
      visible: false,
      title: '',
      message: ''
    },
    trazabilidad: {
      visible: false
    },
    enterprise: {
      name: '',
      code: '',
      abbreviation: ''
    },
    formAfiliado: {
      visible: false,
      afiliadoSeleccionado: {},
      edit: false,
    },
    idRegistro: null,
    refreshToken: '',
    tipoIdentificacion: null,
    numeroIdentificacion: null,
    logout: false,
    error: false,
  },
  mutations: {
    updateAuth(state, auth) {
      state.auth = auth
    },
    snackbar(state, message, color, time = 5000) {
      state.notify.message = message
      state.notify.color = color
      state.notify.visible = true
      state.notify.time = time
    },
    showInformation(state, message) {
      state.notify.message = message
      state.notify.color = 'info'
      state.notify.visible = true
    },
    showSuccess(state, message) {
      state.notify.message = message
      state.notify.color = 'success'
      state.notify.visible = true
    },
    showWarning(state, message) {
      state.notify.message = message
      state.notify.color = 'warning'
      state.notify.visible = true
    },
    showError(state, message) {
      state.notify.message = message
      state.notify.color = 'error'
      state.notify.visible = true
    },
    hideNotify(state) {
      state.notify.visible = false
    },
    showBusy(state, busy) {
      state.busy.title = busy.title
      state.busy.message = busy.message
      state.busy.visible = true
    },
    hideBusy(state) {
      state.busy.visible = false
    },
    setEnterprise(state, enterprise) {
      state.enterprise.abbreviation = enterprise.abreviatura;
      state.enterprise.name = enterprise.nombre;
      state.enterprise.code = enterprise.idEmpresa;
    },
    addAfil(state) {
      state.formAfiliado.visible = true;
      state.formAfiliado.edit = false;
    },
    editAfil(state, afiliado) {
      state.formAfiliado.visible = true;
      state.formAfiliado.edit = true;
      state.formAfiliado.afiliadoSeleccionado = afiliado;
    },
    setAuthenticationToken(state, token) {
      state.auth.token = token;
    },
    setRefreshToken(state, refreshToken) {
      state.refreshToken = refreshToken;
    },
    setIdRegistro(state, idRegistro) {
      state.idRegistro = idRegistro;
    },
    setTipoIdentificacion(state, tipoIdentificacion) {
      state.tipoIdentificacion = tipoIdentificacion;
    },
    setNumeroIdentificacion(state, numeroIdentificacion) {
      state.numeroIdentificacion = numeroIdentificacion;
    },
    setLogout(state, logout) {
      state.logout = logout;
    },
    setError(state, error) {
      state.error = error;
    },
  },
  actions: {
    handleAuthentication({ commit }, token) {
      commit('setAuthenticationToken', token);
    },
    handleRefresh({ commit }, refreshToken ) {
      commit('setRefreshToken', refreshToken );
    },
    // loadEnterpriseData({ commit },abbreviation) {
    //   commit('setEnterpriseAbbreviation', abbreviation);
    // }
  },
  getters: {

  },
  modules: {

  },
  plugins: [vuexLocalStorage.plugin]
})
