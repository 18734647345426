/*################################################################################## 
###### HERINCOHAN                                                             ###### 
###### @author: Diego Alexander Villalba                                      ###### 
###### @date: Octubre 2023                                                    ###### 
##################################################################################*/

const Example = () => import('./Example.vue');


const exampleRoutes = [
   {
      path: '/modules/example',
      name: 'modules.example',
      component: Example,
      children: [
      ],
      meta: {}
   },
];


export default exampleRoutes;