/*################################################################################## 
###### HERINCOHAN                                                             ###### 
###### @author: Diego Alexander Villalba                                      ###### 
###### @date: Octubre 2023                                                    ###### 
##################################################################################*/

import { Role } from "../../../router/role.js";
import homologatorRoutes from './homologator/routes/homologator.routes.js';

const Tools = () => import('./Tools.vue');

const toolsRoutes = [
  {
    path: '/modules/tools',
    name: 'modules.tools',
    component: Tools,
    children: [
      ...homologatorRoutes
    ],
    meta: { authorize: Role.Herramientas }
  },
];


export default toolsRoutes;