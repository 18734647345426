<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: John David Vásquez Serna                                      ###### -->
<!-- ###### @date: Febrero 2024                                                  ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->

<template>
    <v-app>
        <v-main style="padding: 0 0 !important; padding-bottom: 2rem; overflow-y: auto;" class="fill-height d-flex align-center justify-center">
            <div class="d-flex justify-center">
                <img class="shadow-img" style="width: 200px;" src="@/assets/images/logoCohanMasVital.png"/>
            </div>
            <div class="d-flex justify-center">
                <v-card class="elevation-3">
                    <v-img></v-img>
                    <v-card-title class="headline justify-center">Recuperación de la cuenta</v-card-title>
                    <v-card-text style="width: 23rem; margin-left: 1.5rem; margin-right: 1rem;">
                        <v-form ref="form" lazy-validation>
                            <div class="d-flex justify-center">
                                <span style="color: gray;"> 
                                    <span>{{ message }}</span>
                                    por favor ingrese al sitio web correspondiente y siga las instruciones para completar el proceso,
                                    una vez completado puede usar su nueva contraseña para ingresar al aplicativo.
                                </span>
                            </div>
                            <div class="d-flex justify-center mt-5 mb-1">
                                <v-btn color="#0d47a1" rounded @click="redirecionarAlLogin()">
                                    <span style="color: #ffffff">VOLVER</span>
                                </v-btn>
                            </div>
                        </v-form>
                    </v-card-text>
                </v-card>
            </div>

            <!-- Botón para abrir el diálogo de privacidad-->
            <div class="pieDePaginaContainer">
                <div class="d-flex justify-center mt-0">
                    <span class="pieDePagina" style="font-size: 15px">©</span>
                    <span>&nbsp;</span>
                    <span class="pieDePagina">{{ new Date().getFullYear() }} COHAN - Todos los derechos reservados.</span>
                    <span>&nbsp;</span>
                    <a class="pieDePagina" @click="abrirDialogoTerminos">Privacidad</a>
                    <PoliticaPrivacidad/>
                </div>
            </div>

        </v-main>
    </v-app>
</template>

<!-- #################################################################################### -->
<!-- ###### Sección de Scripts                                                     ###### -->
<!-- #################################################################################### -->
<script>
import PoliticaPrivacidad from '../../../components/PoliticaPrivacidad.vue';

export default {
    name: 'CorreoAfiliado',
    components: {
        PoliticaPrivacidad,
    },
    data() {
        return {
            correoAfiliado: 'example@gmail.com',
            message: '',
        };
    },
    /**
     * Método que se ejecuta cuando el componente es montado en el DOM.
     * 
     * Este método obtiene el parámetro de consulta 'message' de la URL actual y lo asigna a la propiedad 'message' del componente, si existe.
     */
    mounted() {
        const hash = window.location.hash;
        const queryString = hash.substring(hash.indexOf('?'));
        const urlParams = new URLSearchParams(queryString);
        const stringMessage = urlParams.get('message');
        if (stringMessage) {
            this.message = stringMessage;
        }
    },
    methods: {
        /**
         * Método para ocultar parte del correo electrónico.
         * 
         * Este método oculta parte del correo electrónico, dejando visible solo los dos últimos caracteres del nombre de usuario.
         * 
         * @param {string} correo - El correo electrónico que se va a ocultar parcialmente.
         * @returns {string} El correo electrónico con parte del nombre de usuario oculto.
         */
        ocultarCorreo(correo) {
            const arrobaIndex = correo.indexOf('@');
            if (arrobaIndex > 1) {
                const caracteresVisibles = 2;
                const parteVisible = '*'.repeat(arrobaIndex - caracteresVisibles) + correo.substring(arrobaIndex - caracteresVisibles, arrobaIndex);
                const dominio = correo.substring(arrobaIndex);
                return parteVisible + dominio;
            }
            return correo;
        },
        /**
         * Método usado para abrir el dialogo que contiene las politicas de privacidad.
         */
        abrirDialogoTerminos() {
            this.$root.$emit('abrirDialogo');
        },
        /**
         * Método para redireccionar al usuario a la página de inicio de sesión externa.
         * Cambia la ubicación actual del navegador a la ruta de inicio de sesión externa.
         */
        redirecionarAlLogin() {
            window.location.href = `/#/external/login`;
            window.location.reload();
        },
    },
   
  };
</script>
  
<!-- #################################################################################### -->
<!-- ###### Sección de Estilos                                                     ###### -->
<!-- #################################################################################### -->
<style scoped>
.boton-actualizar {
    background-color: #0d47a1 !important;
    color: #fff;
    border-radius: 18px;
}
.shadow-img {
    filter: drop-shadow(0px 3px 3px rgba(63, 63, 65, 0.7));
}
.camposVacios {
    color: #f80505;
    display: flex;
    justify-content: center;
    font-size: small;
}
.pieDePaginaContainer {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #0d47a1; 
    height: 1.5rem; 
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 10px -5px 10px rgba(0, 0, 0, 0.3);
}
.pieDePagina {
    color: white;
    font-size: small;
    margin-top: 0.3rem;
}
</style>