<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: Oscar Andrés Ochoa Villegas                                   ###### -->
<!-- ###### @date: Enero 2022                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <v-container>
    <v-layout>
      <v-card width="100%">
        <v-card-title>Bienvenido a la Primera Versión HERINCOHAN 4.0</v-card-title>
        <v-card-text>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>2022-JUN-06 Notas de versión</v-list-item-subtitle>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="1" align="center">
                    <v-img class="mt-4" src="@/assets/herinco-small.png"></v-img>
                  </v-col>
                  <v-col>
                    <div><br />
                      <p class="customVerticalSapce">Esta es la primera versión del sistema de información HERINCOHAN
                        4.0, este sistema es la evolución del sistema HERINCO de categoría WMS, logístico y farmacéutico
                        de la Cooperativa de Hospitales de Antioquia - COHAN el cual esta basado en arquitecturas de
                        última generación para brindar el mejor desempeño y experiencia de usuario, esperamos que el uso
                        sea tan ameno y agradable como ha sido su construcción.</p>
                      <p class="customVerticalSapce">Esta versión contiene:
                      <ul>
                        <li>Módulo de seguridad y autenticación en el sistema.</li>
                        <li>Módulo de configuración y especificamente la configuración de medios de picking en farmacia.
                        </li>
                        <li>Módulo de dispensación para la sistematización de las dispensaciones en Servicios
                          Farmaceuticos.</li>
                        <li>Integraciones con el sistema ECM Docuware y con Herinco 1.0.</li>
                        <li>Integraciones necesarias para la auditoria de facturación realizada en Docuware.</li>
                        <li>Primeras visualizaciónes de tableros Analíticos usando PowerBI.</li>
                      </ul>
                      </p>
                    </div>
                  </v-col>
                </v-row>
              </v-container>


            </v-list-item-content>
          </v-list-item>
        </v-card-text>
      </v-card>
    </v-layout>
  </v-container>
</template>

<!-- #################################################################################### -->
<!-- ###### Sección de Scripts                                                     ###### -->
<!-- #################################################################################### -->
<script>
export default {
  name: 'home',
}
</script>

<!-- #################################################################################### -->
<!-- ###### Sección de Estilos                                                     ###### -->
<!-- #################################################################################### -->
<style scoped>
.customVerticalSapce {
  line-height: 1.3;
}
</style>