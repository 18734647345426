/*################################################################################## 
###### HERINCOHAN                                                             ###### 
###### @author: Diego Alexander Villalba                                      ###### 
###### @date: Octubre 2023                                                    ###### 
##################################################################################*/

const Shopping = () => import('../Shopping.vue');
const MovimientoDiario = () => import('../MovimientoDiario.vue');
const OrdenesDeCompra = () => import('../OrdenesDeCompra.vue');

import { Role } from "@/router/role.js";


const shoppingRoutes = [
  {
    path: 'shopping',
    name: 'modules.analytics.shopping',
    component: Shopping,
    children: [
      {
        path: 'movimiento-diario',
        name: 'modules.analytics.shopping.movimientoDiario',
        component: MovimientoDiario,
        meta: { authorize: Role.Analitica_Comercial && Role.Analitica_Compras_MovimientoDiario }
      },
      {
        path: 'ordenes-de-compra',
        name: 'modules.analytics.shopping.ordenesDeCompra',
        component: OrdenesDeCompra,
        meta: { authorize: Role.Analitica_Comercial && Role.Analitica_Compras_OrdenesDeCompra }
      },

    ],
    meta: { authorize: Role.Analitica_Comercial }
  },
];


export default shoppingRoutes;