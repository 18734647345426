/*################################################################################## 
###### HERINCOHAN                                                             ###### 
###### @author: Diego Alexander Villalba                                      ###### 
###### @date: Octubre 2023                                                    ###### 
##################################################################################*/

const Logistics = () => import('../Logistics.vue');
const ReportesTracking = () => import('../ReportesTracking.vue');
const ReportesTrackingEntregas = () => import('../ReportesTrackingEntregas.vue');

import { Role } from "@/router/role.js";


const logisticsRoutes = [
  {
    path: 'logistics',
    name: 'modules.reports.logistics',
    component: Logistics,
    children: [
      {
        path: 'tracking',
        name: 'modules.reports.logistics.tracking',
        component: ReportesTracking,
        meta: { authorize: Role.logisticsRoutes && Role.Reportes_Logistica_Tracking }
      },
      {
        path: 'tracking-entregas',
        name: 'modules.reports.logistics.trackingEntregas',
        component: ReportesTrackingEntregas,
        meta: { authorize: Role.logisticsRoutes && Role.Reportes_Logistica_TrackingEntregas }
      },
    ],
    meta: { authorize: Role.logisticsRoutes }
  },


];


export default logisticsRoutes;