<!-- #################################################################################### -->
<!-- ###### HERINCOHAN                                                             ###### -->
<!-- ###### @author: Diego Alexander Villalba Cely                                 ###### -->
<!-- ###### @date: Abril 2023                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <div class="overflow-y-hidden">
    <v-toolbar color="primary" dark flat>
      <v-tabs v-model="tab" align-with-title>
        <v-tabs-slider color="yellow"></v-tabs-slider>
        <v-tab>Seleccione su empresa</v-tab>
      </v-tabs>
    </v-toolbar>

    <section class="d-flex justify-space-around flex-wrap overflow-y-auto">
      <template v-for="(enterprise, i) in dataEmpresas">
        <v-card @click="toEnterprise(enterprise)" class="ma-3" color="blue-grey lighten-5" width="325px" height="250px"
          :key="i" link>
          <v-card-title>
            <span class="title-enterprise">{{ enterprise.nombre }}</span>
          </v-card-title>
          <!-- <v-icon class="icon-enterprise" color="grey lighten-2">domain_add</v-icon> -->
          <img class="icon-enterprise" :src="enterprise.logo" :alt="enterprise.nombre">
          <div class="d-flex ms-4 code-enterprise">
            <p class="font-weight-bold">CÓDIGO:</p>
            <span>{{ enterprise.idEmpresa }}</span>
          </div>
        </v-card>
      </template>
    </section>
  </div>
</template>

<!-- #################################################################################### -->
<!-- ###### Sección de scripts                                                        ###### -->
<!-- #################################################################################### -->
<script>
import { mapState, mapMutations } from "vuex";

export default {
  name: 'Index',
  data: () => ({
    tab: null,
    rolesKeycloak: null,
    codigosEmpresas: [],
    dataEmpresas: [],
    usuario: "",
    hasEnterprise: false,
  }),
  mounted() {
    this.enterprise.code = "";
    this.enterprise.name = "";
    this.enterprise.abbreviation = "";
    this.auth.roles = [];
    this.rolesKeycloak = this.auth.keycloak.realmAccess.roles;
    this.usuario = this.auth.username;
    this.empresasUsuario();
  },
  computed: {
    ...mapState(["auth", "enterprise", "notify"]),
  },
  watch: {
  },
  methods: {
    ...mapMutations(["setEnterprise", "showError"]),

    async empresasUsuario() {
      const codEmpresas = await this.$http.get(`msa-setting/api/usem/relacion/${this.usuario}`)
        .then(({ data }) => {
          if (!data) this.showSuccess("El usuario no está relacionado con ninguna empresa.");
          this.codigosEmpresas = data
        }).catch(err => console.log(err))

      if (codEmpresas != []) {
        await this.$http.post('msa-setting/api/empresa/list', this.codigosEmpresas)
          .then(({ data }) => this.dataEmpresas = data)
          .catch(err => console.log(err))
      }
    },
    toEnterprise(enterprise) {
      this.auth.roles = this.rolesKeycloak;
      localStorage.idEmpresa = enterprise.idEmpresa;
      this.setEnterprise(enterprise)
      this.$router.push({ name: 'modules.home' });
    }

  }
}
</script>

<!-- #################################################################################### -->
<!-- ###### Sección de style                                                       ###### -->
<!-- #################################################################################### -->
<style scoped>
.v-card {
  transition: opacity .4s ease-in-out;
  position: relative;
  z-index: 1;
}

.v-card:hover {
  box-shadow: 0px 5px 50px 20px rgba(0, 0, 0, 0.1), 0px 0px 57px -30px rgba(0, 0, 0, 0.1);
  transition: box-shadow .5s ease-in-out;
  background-color: white !important;
}

.v-card:hover .title-enterprise:first-child {
  color: #0D47A1;
}

.v-card:hover .icon-enterprise {
  filter: grayscale(0);
  transition: filter .5s ease-in-out;
}

.code-enterprise {
  position: absolute;
  bottom: 0;
}

.code-enterprise>span {
  margin-left: 14rem;
}

.icon-enterprise {
  width: 6rem;
  height: 5rem;
  position: relative;
  margin: -3% 32% 6% 36%;
  filter: grayscale(100%);
  z-index: 1;
}

.subHeader-content {
  width: 100%;
}

.v-card__title {
  word-break: normal !important;
  text-align: center !important;
  display: grid !important;
}

.v-toolbar {
  height: 38px !important;
}

::v-deep .v-sheet .v-toolbar__content {
  height: 35px !important;
}
</style>