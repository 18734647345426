/*################################################################################## 
###### HERINCOHAN                                                             ###### 
###### @author: Diego Alexander Villalba                                      ###### 
###### @date: Octubre 2023                                                    ###### 
##################################################################################*/

const PharmaceuticalServices = () => import('../PharmaceuticalServices.vue');
const Pqrs = () => import('../Pqrs.vue');
const GestionMx = () => import('../GestionMx.vue');
const Informe60Anios = () => import('../Informe60Anios.vue');
const Informe70Anios = () => import('../Informe70Anios.vue');


import { Role } from "@/router/role.js";


const pharmaceuticalServiceRoutes = [
  {
    path: 'pharmaceutical-service',
    name: 'modules.analytics.pharmaceuticalService',
    component: PharmaceuticalServices,
    children: [
      {
        path: 'pqrs',
        name: 'modules.analytics.pharmaceuticalService.pqrs',
        component: Pqrs,
        meta: { authorize: Role.Analitica_ServiciosFarmaceuticos && Role.Analitica_ServiciosFarmaceuticos_Pqrs }
      },
      {
        path: 'gestion-de-mx',
        name: 'modules.analytics.pharmaceuticalService.gestionDeMx',
        component: GestionMx,
        meta: { authorize: Role.Analitica_ServiciosFarmaceuticos && Role.Analitica_ServiciosFarmaceuticos_GestionDeMx }
      },
      {
        path: 'informe-60-anios',
        name: 'modules.analytics.pharmaceuticalService.informe60años',
        component: Informe60Anios,
        meta: { authorize: Role.Analitica_ServiciosFarmaceuticos && Role.Analitica_ServiciosFarmaceuticos_Informe60Años }
      },
      {
        path: 'informe-70-anios',
        name: 'modules.analytics.pharmaceuticalService.informe70años',
        component: Informe70Anios,
        meta: { authorize: Role.Analitica_ServiciosFarmaceuticos && Role.Analitica_ServiciosFarmaceuticos_Informe70Años }
      },

    ],
    meta: { authorize: Role.Analitica_ServiciosFarmaceuticos }
  },
];


export default pharmaceuticalServiceRoutes;