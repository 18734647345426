/*################################################################################## 
###### HERINCOHAN                                                             ###### 
###### @author: Diego Alexander Villalba                                      ###### 
###### @date: Octubre 2023                                                    ###### 
##################################################################################*/

import { Role } from "@/router/role.js";
import dispensationRoutes from './dispensation/routes/dispensation.routes.js';

const PharmaceuticalServicesMain = () => import('./PharmaceuticalServicesMain.vue');

const pharmaceuticalServicesMainRoutes = [
  {
    path: '/modules/pharmaceutical-services',
    name: 'modules.pharmaceuticalServicesMain',
    component: PharmaceuticalServicesMain,
    children: [
      ...dispensationRoutes

    ],
    meta: { authorize: Role.ServiciosFarmaceuticos }
  },
];


export default pharmaceuticalServicesMainRoutes;