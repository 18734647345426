<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: John David Vásquez Serna                                      ###### -->
<!-- ###### @date: Abril 2024                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->

<template>
    <v-dialog v-model="dialogoTerminos" max-width="40rem" scrollable persistent>
        <v-card>
            <v-card-title class="headline d-flex">
                <div style="width: 95%;" class="d-flex justify-center pl-2">
                    <span class="text-h6">Política de privacidad y datos personales</span>
                </div>
                <div style="width: 5%;" class="d-flex justify-end">
                    <v-icon color="primary" @click="cerrarDialogoTerminos">close</v-icon>
                </div>
            </v-card-title>
            <v-card-text>
                <div>
                    <p style="color: black;">
                            La Cooperativa de Hospitales de Antioquia cuya sigla es COHAN, es una entidad sin ánimo 
                            de lucro con reconocimiento de personería jurídica según Resolución no. 2380, del 28 de 
                            Octubre de 1983 e inscrita en la Cámara de Comercio el 6 de febrero de 1997, identificada 
                            con NIT 890.985.122-6, con domicilio en la Carrera 48 No. 24 – 104 de la ciudad de Medellín 
                            cuyo objeto general es promover el desarrollo integral de sus asociados, y a través de ellos 
                            la promoción de salud en la comunidad.
                        <br>
                        <br>
                            En todas las actuaciones de la Cooperativa, a través de sus unidades de negocio y por todos 
                            los medios en los cuales se solicita información, físico, electrónico, virtual, acceso telefónico, 
                            vía web, entre otros, está comprometida con el respeto de los derechos de todos los titulares 
                            de los datos personales que estén bajo su custodia y sometidos a los tratamientos adelante 
                            informados. Por esto se adopta la presente Política, la cual, es de obligatorio cumplimiento 
                            y aplicación en todas las actividades que involucren el tratamiento de datos personales.
                        <br>
                        <br>
                            En consecuencia, COHAN comunica a los grupos de interés con los cuales se relaciona la 
                            Política de Privacidad que ha sido adoptada para dar cumplimiento a lo dispuesto en el 
                            régimen legal vigente contenido en las Leyes Estatutarias 1581 de 2012 sobre Protección 
                            de Datos Personales y 1266 de 2008 sobre Habeas Data Financiero, en lo pertinente.
                        <br>
                        <br>
                            En cumplimiento de las facultades estatutarias y legales, COHAN trata datos personales de 
                            sus grupos de interés en ejecución de su objeto social consistente principalmente en la 
                            adquisición, comercialización y suministro de medicamentos, dispositivos médicos e 
                            insumos hospitalarios, así como la prestación de servicios farmacéuticos a los usuarios de 
                            sus clientes, asociados o no, como cooperativa multiactiva del sector de provisión de bienes 
                            y servicios en salud, posibilitando un proceso de dispensación de medicamentos hacia un 
                            modelo propio que incluye acciones integrales tanto en el ámbito ambulatorio como 
                            intrahospitalario. Igualmente, para estudiantes y participantes del servicio de formación, 
                            capacitación y educación continua que ofrece a través de su unidad de negocios Politécnico. 
                            Lo anterior sin perjuicio de los tratamientos de datos personales vinculados a otros fines del 
                            cooperativismo.
                        <br>
                        <br>
                            Los datos personales en custodia de COHAN, en su condición de Responsable y/o 
                            Encargado según el caso, serán tratados cumpliendo los principios y regulaciones previstas 
                            en las leyes colombianas y las buenas prácticas aplicables al régimen de protección de 
                            datos personales. Así mismo, cumpliendo las normas vigentes, se informa que los datos
                            personales podrán ser tratados directamente y/o a través de terceros, en condición de 
                            encargados del tratamiento de datos, en centros de datos ubicados dentro o fuera de 
                            Colombia, en territorios como Estados Unidos, Europa, Latinoamérica u otras regiones.
                        <br>
                        <br>
                            En consecuencia, serán tratados conforme las siguientes finalidades de carácter general:
                        <br>
                        <br>
                        <ul>
                            <li>
                                Prestar servicios de: comercialización de medicamentos e insumos hospitalarios a 
                                clientes asociados y no asociados, prestación de servicios farmacéuticos mediante 
                                la dispensación de medicamentos a los usuarios y pacientes de sus clientes; para la 
                                prestación de servicios de formación y capacitación a estudiantes y participantes, y 
                                demás servicios incluidos en el desarrollo de su objeto.
                            </li>
                            <li>
                                Cumplir con las obligaciones y/o compromisos derivados de las relaciones, 
                                contractuales o no, existentes con sus grupos de interés.
                            </li>
                            <li>
                                Gestionar las relaciones jurídicas, comerciales o no, originadas en la adquisición de 
                                productos y/o insumos de carácter hospitalario, sin perjuicio de otros servicios para 
                                los cuales este habilitada por los estatutos y la ley en el marco de la filosofía 
                                asociativa de esta cooperativa.
                            </li>
                            <li>
                                Dar cumplimiento a las obligaciones legales y contractuales que involucren datos 
                                personales de sus grupos de interés, sin limitarse a los propios de asociados, 
                                clientes, pacientes y beneficiarios.
                            </li>
                            <li>
                                Comunicar a sus grupos de interés, a través de canales tradicionales y/o virtuales, 
                                información comercial relacionada con la oferta de productos, servicios, eventos, 
                                programas, publicaciones, actualidad del sector, información empresarial, educación, 
                                entre otra relacionada o conexas con su capacidad estatutaria, contractual y/o legal, 
                                sea que se realice de manera directa o no.
                            </li>
                            <li>
                                Suministrar información a las autoridades y/o cooperar con estas cuando sea 
                                requerida para tal finalidad.
                            </li>
                            <li>
                                Gestionar la seguridad de las personas, bienes y activos de información en custodia 
                                de la organización.
                            </li>
                            <li>
                                Desplegar hacia sus grupos de interés actividades de responsabilidad social 
                                empresarial.
                            </li>
                            <li>
                                Administrar la relación comercial y relacionamiento con los grupos de interés.
                            </li>
                            <li>
                                Gestionar el crédito, cartera y cobranza originada en las relaciones contractuales de 
                                orden crediticias con terceros clientes y/o proveedores.
                            </li>
                            <li>
                                Gestionar los riesgos originados en la celebración de los contratos con terceros, a 
                                efectos de prevenir daños económicos propios o a terceros.
                            </li>
                            <li>
                                Administrar y gestionar la relación académica que surge de su unidad de servicios, 
                                Politécnico COHAN, a través de la oferta de cursos y programas de educación para 
                                el sector salud.
                            </li>
                            <li>
                                Cumplir con las regulaciones del sector expedidas por las autoridades competentes.
                            </li>
                        </ul>
                        <br>
                        En el proceso de suministro y servicios de dispensación que presta esta organización, y 
                        acorde con los datos personales recolectados y tratamiento a realizar, en el respectivo aviso 
                        de privacidad se informarán de forma previa las finalidades particulares; nombre o razón 
                        social y datos de contacto del Responsable o Encargado del tratamiento, si el tratamiento 
                        se efectuará por un encargado, caso en el que tal encargado estará bajo la dirección de 
                        COHAN; los derechos que le asisten al titular y los mecanismos dispuestos por esta 
                        organización para dar a conocer la Política de Privacidad.
                        Cualquier persona que haga parte de uno de los grupos de interés, en su condición de titular 
                        o legítimamente autorizado, en relación con tratamiento de sus datos personales tiene 
                        derecho a:
                        <br>
                        <br>
                        <ul>
                            <li>
                                Ejercer su derecho de Habeas Data consistente en conocer, actualizar, rectificar su 
                                información de carácter personal. También podrá oponerse y cancelar sus datos e 
                                información personal en aquellos casos en que ello proceda.
                            </li>
                            <li>
                                Evidenciar la existencia del consentimiento otorgado, salvo que exista autorización 
                                legal para el tratamiento o el mismo se realice en el marco de un contrato.
                            </li>
                            <li>
                                Ejercer las acciones que la ley reconoce en materia de protección de datos 
                                personales y habeas data.
                            </li>
                        </ul>
                        <br>
                        Para el ejercicio del Habeas Data, el titular del dato personal o quien demuestre un legítimo 
                        interés conforme lo señalado en la normatividad vigente, podrá hacerlo a través del siguiente 
                        correo electrónico: habeasdata@cohan.org.co o dirigiendo una comunicación física a la 
                        siguiente dirección en la ciudad de Medellín: Carrera 48 No. 24-104.
                        <br>
                        <br>
                        Quien ejerza el habeas data deberá suministrar con precisión los datos de contacto 
                        solicitados para efecto de tramitar, atender y responder su solicitud y desplegar las cargas 
                        para el ejercicio de sus derechos, en especial los siguientes: a) Nombres completos del 
                        titular. b) Documento de identidad (original). c) Documento de identidad (copia legible).
                        d) Dirección de residencia. e) Ciudad de residencia. f) Teléfono fijo. g) Teléfono móvil.
                        h) Correo electrónico. i) Descripción del requerimiento sobre el o los derechos que desea 
                        acceder referente al tratamiento de los datos personales.
                    </p>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<!-- #################################################################################### -->
<!-- ###### Sección de Scripts                                                     ###### -->
<!-- #################################################################################### -->
<script>
export default {
    data() {
        return {
            dialogoTerminos: false,
        };
    },
    mounted() {
    // Escuchar el evento 'abrirDialogo' del componente padre
        this.$root.$on('abrirDialogo', () => {
            this.dialogoTerminos = true;
        });
    },
    watch: {
       
    },
    methods: {
        /**
         * Método usado para cerrar el dialogo que contiene las politicas de privacidad.
         */
        cerrarDialogoTerminos() {
            this.dialogoTerminos = false;
        },
    },
    beforeDestroy() {
        // Limpia el evento al destruir el componente para evitar fugas de memoria
        this.$root.$off('abrirDialogo');
    },
};
</script>