/*################################################################################## 
###### HERINCO                                                                ###### 
###### @author: John David Vásquez Serna                                      ###### 
###### @date: Enero 2024                                                      ###### 
##################################################################################*/

import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index.js'
import Home from '../views/modules/Home.vue'

import profileRoutes from '../views/modules/profile/profile.routes.js';
import helpRoutes from '../views/modules/help/help.routes.js';
import exampleRoutes from '../views/modules/example/example.routes.js';
import affiliatesRoutes from '../views/modules/affiliates/affiliates.routes.js';
import AffiliatesLogin from '../views/modules/login-affiliates/AffiliatesLogin.vue';

Vue.use(VueRouter)

/**
 * Routes used by the VUE navigation system
 */
const routes = [
  {
    path: '*',
    component: AffiliatesLogin,
  },
  {
    path: '/modules/home',
    name: 'modules.home',
    component: Home,
    meta: { authorize: [] }
  },
  {
    path: '/external/login',
    name: 'external.login',
    component: AffiliatesLogin
  },
  {
    path: '/',
    redirect: '/external/login/modules/affiliates/requests/entrega' 
  },
  
  ...profileRoutes,
  ...helpRoutes,
  ...exampleRoutes,
  ...affiliatesRoutes,
]

/**
 * Route construction
 */
const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

/**
 * Role security restrictions for different views
 */
router.beforeEach((to, from, next) => {

  // redirect to login page if not logged in and trying to access a restricted page
  const { authorize } = to.meta;
  let userRoles = store.state.auth.roles;

  // If you do not have roles due to forced navigation, set the empty roles
  if (userRoles === undefined) {
    userRoles = [];
  }

  if (authorize) {
    // check if route is restricted by role
    if (authorize.length && !userRoles.includes(authorize)) {
      // role not authorized so redirect to home page
      return next({ path: "/modules/home" });
    }
  }
  
  next();
  
});

export default router