/*################################################################################## 
###### HERINCOHAN                                                             ###### 
###### @author: Diego Alexander Villalba                                      ###### 
###### @date: Octubre 2023                                                    ###### 
##################################################################################*/

import { Role } from "@/router/role.js";
import trackingRoutes from './tracking/routes/tracking.routes';

const Logistics = () => import('./Logistics.vue');

const logisticsRoutes = [
  {
    path: '/modules/logistics',
    name: 'modules.logistics',
    component: Logistics,
    children: [
      ...trackingRoutes

    ],
    meta: { authorize: Role.Logistica }
  },
];


export default logisticsRoutes;