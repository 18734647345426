<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: John David Vásquez Serna                                      ###### -->
<!-- ###### @date: Febrero 2024                                                  ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->

<template>
    <v-app>
        <v-main style="padding: 0 0 !important; padding-bottom: 2rem; overflow-y: auto;" class="fill-height d-flex align-center justify-center">
            <div class="d-flex justify-center">
                <img class="shadow-img" style="width: 200px;" src="@/assets/images/logoCohanMasVital.png"/>
            </div>
            <div class="d-flex justify-center">
                <v-card class="elevation-3">
                    <v-img></v-img>
                    <v-card-title class="headline justify-center">{{ tituloValidacion }}</v-card-title>
                    <v-card-text style="width: 23rem;">
                        <validation-observer ref="observer">
                            <v-form ref="form" lazy-validation>
                                <div class="d-flex justify-center">
                                    <validation-provider v-slot="{ errors }" :rules="{ obligatorio: true, }" ref="tipoIdentificacion">
                                        <v-autocomplete class="mb-6" id="tipoDocumento" v-model="tipoIdentificacion" :items="listaDeTiposDocumento" outlined  
                                            :menu-props="{offsetY:true, maxHeight: 165, maxWidth: 272}" :error-messages="errors" style="width: 17rem" hide-details
                                            dense label="Tipo de identificación" @click="listarTiposDeDocumentos()">
                                        </v-autocomplete>
                                    </validation-provider>
                                </div>
                                <div class="d-flex justify-center">
                                    <validation-provider v-slot="{ errors }" :rules="{ obligatorio: true, numeric: true, identificacion: 6, allZero: true }" ref="numeroIdentificacion">
                                        <v-text-field class="mb-6" id="numeroIdentificacion" v-model="numeroIdentificacion" dense outlined label="Número identificación" 
                                            :error-messages="errors" style="width: 17rem" maxlength="15" @keypress="validarLetra" @paste="handlePaste($event)" hide-details>
                                        </v-text-field>
                                    </validation-provider>
                                </div>
                                <div class="d-flex justify-center ">
                                    <v-btn class="mr-9 mb-1" color="#0d47a1" rounded outlined @click="redirectToAffiliateLogin">
                                        Cancelar
                                    </v-btn>
                                    <v-btn color="#0d47a1" rounded @click="enviarAfiliado()">
                                        <span style="color: #ffffff">{{ botonValidacion }}</span>
                                    </v-btn>
                                </div>
                                <div v-if="datosIncompletos === true">
                                    <span class="camposVacios">
                                        {{ this.camposVacios }}
                                    </span>
                                </div>
                            </v-form>
                        </validation-observer>
                    </v-card-text>
                </v-card>
            </div>

            <!-- Botón para abrir el diálogo de privacidad-->
            <div class="pieDePaginaContainer">
                <div class="d-flex justify-center mt-0">
                    <span class="pieDePagina" style="font-size: 15px">©</span>
                    <span>&nbsp;</span>
                    <span class="pieDePagina">{{ new Date().getFullYear() }} COHAN - Todos los derechos reservados.</span>
                    <span>&nbsp;</span>
                    <a class="pieDePagina" @click="abrirDialogoTerminos">Privacidad</a>
                    <PoliticaPrivacidad/>
                </div>
            </div>

            <!-- Contenedor para mostrar los mensajes de error -->
            <div v-if="mostrarNotificacion" :class="['notificacion', tipoNotificacion]">
                <span><v-icon :color="tipoNotificacionColor" :class="iconoNotificacionClase" class="rotate-animation-notification size">{{ iconoNotificacion }}</v-icon></span>
                <span>{{ mensajeNotificacion }}</span>
            </div>

        </v-main>
    </v-app>
</template>

<!-- #################################################################################### -->
<!-- ###### Sección de Scripts                                                     ###### -->
<!-- #################################################################################### -->
<script>
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { required, min, numeric } from 'vee-validate/dist/rules';
import PoliticaPrivacidad from '../../../components/PoliticaPrivacidad.vue';

extend('obligatorio', {
    ...required,
    message: 'Campo obligatorio'
})
extend('identificacion', {
    ...min,
    message: 'Mínimo 7 dígitos '
})
extend('numeric', {
    ...numeric,
    message: 'Este campo solo permite números'
})
extend('allZero', {
  validate: value => {
    if (!/\d*[1-9]\d*/.test(value)) {
      return false;
    }
    return true;
  },
  message: 'Formato incorrecto.'
})
  
export default {
    name: 'ValidarAfiliado',
    components: {
        ValidationObserver,
        ValidationProvider,
        PoliticaPrivacidad,
    },
  
    data() {
        return {
            listaDeTiposDocumento: [],
            rules: {
                agregarRules: [
                    v => !!v || "Campo obligatorio",
                ],
            },
            tipoIdentificacion: '',
            numeroIdentificacion: '',
            validarAfiliado: false,
            cambiarContrasena: false,
            tituloValidacion: '',
            botonValidacion: '',
            camposVacios: '',
            datosIncompletos: false,
            validateProgress: false,
            mostrarNotificacion: false,
            mensajeNotificacion: "",
            tipoNotificacion: "",
            tipoNotificacionColor: "",
            iconoNotificacion: "",
            iconoNotificacionClase: "",
        };
    },
    created() {
        this.listarTiposDeDocumentos();
    },
    /**
     * Configura el componente basado en los parámetros de la URL.
     * 
     * Extrae los parámetros de la URL para determinar si se está validando un afiliado o cambiando una contraseña.
     * Configura el título y el botón de acuerdo a los valores de los parámetros en la URL.
     */
    mounted() {
        const contrasena = this.$route.query.contrasena === 'true';
        const registrarse = this.$route.query.registrarse === 'true';
        this.validarAfiliado = registrarse;
        this.cambiarContrasena = contrasena;
        if (this.validarAfiliado) {
            this.tituloValidacion = 'Validar afiliación';
            this.botonValidacion = 'Validar';
        } else if (this.cambiarContrasena) {
            this.tituloValidacion = 'Ingresa tus datos';
            this.botonValidacion = 'Continuar';
        }
    },
    methods: {
        /**
         * Método usado para abrir el dialogo que contiene las politicas de privacidad.
         */
        abrirDialogoTerminos() {
            this.$root.$emit('abrirDialogo');
        },
        /**
         * Método para redireccionar al usuario a la página de inicio de sesión externa.
         * Cambia la ubicación actual del navegador a la ruta de inicio de sesión externa.
         */
        redirectToAffiliateLogin() {
            window.location.href = `/#/external/login`;
            window.location.reload();
        },
        /**
         * Recupera una lista de tipos de documentos desde el servidor y la almacena en la propiedad
         * 'listaDeTiposDocumento' del componente.
         * @throws {Error} Si ocurre un error durante la solicitud al servidor.
         */
        listarTiposDeDocumentos() {
            this.listaDeTiposDocumento = [];
            this.$http.get(`msa-external/public/tipo-documento/list`)
            .then((response) => {
                this.listaDeTiposDocumento = response.data.map(item => ({
                    text: `${item.descripcion}`,
                    value: item.tipoDocumento,
                    tipoDocumento: item.tipoDocumento,
                }))
                this.tipoIdentificacion = "CC";
            }).catch((error) => {
                this.manejarError(error);
            })
        },
        /**
         * Valida que la tecla presionada sea un número.
         * Evita la entrada de caracteres no numéricos.
         * @param {Event} event - Evento de tecla presionada.
         */
        validarLetra(event) {
            const teclaPresionada = event.key
            if (teclaPresionada.match(/[^0-9]/)) {
                event.preventDefault();
            }
        },
        /**
         * Método para enviar los datos del afiliado al servidor y manejar respuestas.
         * 
         * Se valida y verifica la integridad de los campos de identificación del afiliado.
         * Si los campos están completos y sin errores, se envían los datos al servidor.
         * 
         * Si se esta validando la afiliación del usuario, redirige a la página de registro con los datos de la afiliación.
         * Si se esta recuperando la contraseña, redirige a la página de correo para mostrar la respuesta del servidor.
         * 
         * Los errores se manejan mostrando mensajes en la interfaz y registrándolos en la consola.
         */
        enviarAfiliado() {
            const tI = this.tipoIdentificacion;
            const nI = this.numeroIdentificacion;
            if (tI === '' || nI === '' ) {
                this.camposVacios = 'Por favor, complete todos los campos.';
                this.datosIncompletos = true;
                return;
            }
            const tipoIdentificacionErrors = this.$refs.tipoIdentificacion.errors;
            const numeroIdentificacionErrors = this.$refs.numeroIdentificacion.errors;
            if (tipoIdentificacionErrors.length > 0 || numeroIdentificacionErrors.length > 0) {
                this.camposVacios = 'Por favor, complete todos los campos correctamente.';
                this.datosIncompletos = true;
                return;
            }
            this.camposVacios = '';
            this.datosIncompletos = false;
            const datosAfiliado = {
                idEmpresa: 1,
                tipoDocumento: this.tipoIdentificacion,
                numeroDocumento: this.numeroIdentificacion
            }
            if (this.botonValidacion === 'Validar') {
                this.$http.post(`msa-external/public/validar`, datosAfiliado)
                .then((response) => {
                    if (response.data.status === 0) {
                        this.camposVacios = response.data.message;
                        this.datosIncompletos = true;
                    } else {
                        if (response.data.direccion !== undefined && response.data.direccion !== null && response.data.direccion !== '') {
                            const almohadilla = response.data.direccion.replace(/#/g, '_');
                            response.data.direccion = almohadilla;
                            const queryParams = response.data ? `?data=${JSON.stringify(response.data)}` : '';
                            const redirectUrl = '/#/external/registroAfiliado';
                            window.location.href = `${redirectUrl}${queryParams}`;
                            window.location.reload();
                        } else {
                            response.data.tipoDocumento = this.tipoIdentificacion;
                            response.data.numeroDocumento = this.numeroIdentificacion;
                            const queryParams = response.data ? `?data=${JSON.stringify(response.data)}` : '';
                            const redirectUrl = '/#/external/registroAfiliado';
                            window.location.href = `${redirectUrl}${queryParams}`;
                            window.location.reload();
                        }
                        this.tipoIdentificacion = '';
                        this.numeroIdentificacion = '';
                        this.camposVacios = '';
                        this.datosIncompletos = false;
                        this.$refs.observer.reset();
                    }
                    this.validateProgress = false;
                }).catch((error) => {
                    this.manejarError(error);
                })
            } else {
                this.$http.get(`msa-external/public/recuperar?tipoDocumento=${this.tipoIdentificacion}&numeroDocumento=${this.numeroIdentificacion}`)
                .then((response) => {
                    if (response.data.status === 0) {
                        this.camposVacios = response.data.message;
                        this.datosIncompletos = true;
                    } else if (response.data.status === 1) {
                        const redirecUrlCorreo = `/#/external/correoAfiliado`;
                        const params = response.data.message ? `?message=${response.data.message}` : '';
                        window.location.href = `${redirecUrlCorreo}${params}`;
                        window.location.reload();
                    }
                }).catch((error) => {
                    this.manejarError(error);
                })
            }
            
        },
        /**
         * Maneja el evento de pegado en un campo de entrada, permitiendo solo valores numéricos.
         * @param {Event} event El evento de pegado.
         */
        handlePaste(event) {
            event.preventDefault();
            const clipboardData = event.clipboardData || window.clipboardData;
            const pastedData = clipboardData.getData('text');
            if (!/^\d+$/.test(pastedData)) {
                return;
            }
            this.numeroIdentificacion = pastedData;
        },
        /**
         * Muestra una notificación global en el componente.
         *
         * @param {string} mensaje - Mensaje que se mostrará en la notificación.
         * @param {string} tipo - Tipo de la notificación (por defecto, "error").
         * @param {string} icono - Icono de la notificación (por defecto, "highlight_off").
         */
         mostrarNotificacionGlobal(mensaje, tipo, icono) {
            this.mostrarNotificacion = true;
            this.tipoNotificacion = tipo || "advertencia"; // Tipo predeterminado es "error"
            this.tipoNotificacionColor = this.obtenerColorNotificacion(this.tipoNotificacion);
            this.iconoNotificacion = icono || "highlight_off"; // Icono predeterminado es "highlight_off"
            this.mensajeNotificacion = mensaje;
            this.iconoNotificacionClase = this.obtenerClaseIcono(this.tipoNotificacion);

            // Cierra la notificación después de 5 segundos
            setTimeout(() => {
                this.cerrarNotificacion();
            }, 5000); 
        },
        /**
         * Cierra la notificación
         */
        cerrarNotificacion() {
            this.mostrarNotificacion = false;
            this.mensajeNotificacion = "";
            this.tipoNotificacion = "";
        },
        /**
         * Obtiene el color correspondiente al tipo de notificación.
         * 
         * @param {string} tipo - Tipo de notificación.
         * @returns {string} - Color de la notificación.
         */
        obtenerColorNotificacion(tipo) {
            switch (tipo) {
                case "advertencia":
                return "#f80505";
                default:
                return "#f80505"; 
            }
        },
        /**
         * Obtiene la clase de icono correspondiente al tipo de notificación.
         * 
         * @param {*} tipo  - Tipo de notificación.
         * @returns {string} - Clase de icono.
         */
        obtenerClaseIcono(tipo) {
            switch (tipo) {
                case "advertencia":
                return "advertencia-icon";
                default:
                return "advertencia-icon";
            }
        },
        /**
         * Maneja errores y muestra notificaciones correspondientes.
         * 
         * @param {*} error - Objeto de error.
         */
        manejarError(error) {
            if (error.response) {
                if (error.response.status === 503) {
                    this.mostrarNotificacionGlobal("Error en la red, inténtalo más tarde.", "advertencia", "highlight_off" );
                } else if (error.response.status === 401) {
                this.mostrarNotificacionGlobal("Error de autorización, contacta con el administrador.", "advertencia", "highlight_off" );
                } else {
                    this.mostrarNotificacionGlobal("Error inesperado, contacta con el administrador.", "advertencia", "highlight_off");
                }
            } else {
                this.mostrarNotificacionGlobal("Error inesperado, contacta con el administrador.", "advertencia", "highlight_off");
            }
        },
    },
   
  };
</script>
  
<!-- #################################################################################### -->
<!-- ###### Sección de Estilos                                                     ###### -->
<!-- #################################################################################### -->
<style scoped>
.boton-actualizar {
    background-color: #0d47a1 !important;
    color: #fff;
    border-radius: 18px;
}
.shadow-img {
    filter: drop-shadow(0px 3px 3px rgba(63, 63, 65, 0.7));
}
.camposVacios {
  color: #f80505;
  display: flex;
  justify-content: center;
  font-size: small;
}
.pieDePaginaContainer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #0d47a1; 
  height: 1.5rem; 
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 10px -5px 10px rgba(0, 0, 0, 0.3);
}
.pieDePagina {
  color: white;
  font-size: small;
  margin-top: 0.3rem;
}
.notificacion {
  position: fixed;
  top: 50px;
  right: 20px;
  padding: 15px;
  border-radius: 5px;
  z-index: 9999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: opacity 0.5s ease-in-out;
}
.advertencia {
  background-color: #ffffffd7 !important;
  color: #f80505;
}
.notificacion span:last-child {
  cursor: pointer;
  margin-right: 10px;
  padding-top: 3px;
}
.advertencia-icon {
  color: #f80505;
}
.rotate-animation-notification {
  animation: rotate-notification 1s ease-in-out; 
}
@keyframes rotate-notification  {
    0% {
        transform: rotateX(180deg);
    }

    50% {
        transform: rotateX(0deg);
    }

    100% {
        transform: rotateX(180deg);
    }
}
.size {
    font-size: xxx-large;
    font-weight: bold;
}
::v-deep .notificacionError .v-data-footer {
  padding-top: 10px;
  justify-content: end;
  border: none;
}
</style>
  