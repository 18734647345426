<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: John David Vásquez Serna                                      ###### -->
<!-- ###### @date: julio 2024                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>

  <!-- Formulario para ingresar la dirección -->
  <v-card>
    <v-card-title class="bg-white">
      <span>{{ titulo }}</span>
    </v-card-title>

    <v-card-text>
      <v-container class="pa-0">
        <v-radio-group v-model="tiposDireccion" row hide-details class="mt-0"> 
          <v-radio class="invisible-radio"></v-radio>
          <v-radio label="Urbana" @click="direccionUrbana = true, direccionRural = false" value="urbana"></v-radio>
          <v-radio label="Rural" @click="direccionRural = true, direccionUrbana = false" value="rural"></v-radio>
        </v-radio-group>

        <!-- form para direccion urbana -->
        <v-form v-show="direccionUrbana" ref="formularioDireccionUrbana" class="mt-6">
          <!-- Tipo via, número-letra-bis de la via y numPla de la via -->
          <v-row class="pr-2" align="start">
            <!-- Tipo via -->
            <v-col cols="12" sm="4" md="4" class="pa-0 pl-2">
              <v-autocomplete ref="refTipoVia" v-model="tipoVia" :items="tiposVias" label="Tipo de vía" maxlength="15"
                :rules="rules.direccion" outlined dense :menu-props="menuPropsTipoVia" no-data-text="Sin resultados" 
                @click="viayFieldSize()" @keydown="tabulador($event, 'tipoVia', 'refTipoVia')">
              </v-autocomplete>
            </v-col>
            <!-- Número-letra-bis de la via -->
            <v-col cols="12" sm="5" md="5" class="pa-0 pl-2 d-flex">
              <v-text-field v-model="numVia" label="N°" class="mr-2" :rules="rules.direccion" 
                outlined dense maxlength="5" @keypress="validarLetrasDireccion" @paste.prevent>
              </v-text-field>
              <v-autocomplete ref="refLetraVia" v-model="letraVia" :items="letras" class="mr-2" label="Letra" outlined dense  maxlength="5"
                :menu-props="{offsetY: true, maxHeight: 180}" @keypress="validarLetraEnDireccion" @keydown="tabulador($event, 'letraVia', 'refLetraVia')">
              </v-autocomplete>
              <v-autocomplete ref="refBisVia" v-model="bisVia" :items="letras" label="BIS" outlined dense maxlength="5"
                :menu-props="{offsetY: true, maxHeight: 180}" @keypress="validarLetraEnDireccion" @keydown="tabulador($event, 'bisVia', 'refBisVia')">
              </v-autocomplete>
            </v-col>
            <!-- Cuadrante de la via -->
            <v-col cols="12" sm="3" md="3" class="pa-0 pl-2">
              <v-autocomplete ref="refOrientacionVia" v-model="orientacionVia" :items="['NORTE', 'SUR', 'ESTE', 'OESTE']" label="Cuadrante" outlined dense maxlength="5" 
                :menu-props="{offsetY: true, maxHeight: 180}" no-data-text="Sin resultados" @keydown="tabulador($event, 'orientacionVia', 'refOrientacionVia')">
              </v-autocomplete>
            </v-col>
          </v-row>
          <!-- Número-letra-bis de la via generadora, número-letra de la placa y numPla de la via generadora -->
          <v-row class="pr-2" align="start">
            <!-- Número-letra-bis de la via generadora -->
            <v-col cols="12" sm="5" md="5" class="pa-0 pl-2 d-flex">
              <v-text-field v-model="viaGen" label="N°" :rules="rules.direccion" outlined dense maxlength="5" 
                @keypress="validarLetrasDireccion" class="mr-2" @paste.prevent>
              </v-text-field>
              <v-autocomplete ref="refLetraViaGen" v-model="letraViaGen" :items="letras" class="mr-2" label="Letra" outlined dense maxlength="5" 
                :menu-props="{offsetY: true, maxHeight: 180}" @keypress="validarLetraEnDireccion" @keydown="tabulador($event, 'letraViaGen', 'refLetraViaGen')">
              </v-autocomplete>
              <v-autocomplete ref="refBisViaGen" v-model="bisViaGen" :items="letras" label="BIS" outlined dense maxlength="5"
                :menu-props="{offsetY: true, maxHeight: 180}" @keypress="validarLetraEnDireccion" @keydown="tabulador($event, 'bisViaGen', 'refBisViaGen')">
              </v-autocomplete>
            </v-col>
            <!-- Número-letra de la placa -->
            <v-col cols="12" sm="4" md="4" class="pa-0 pl-2 d-flex">
              <v-text-field v-model="numPla" label="N°" outlined dense maxlength="5" @keypress="validarLetrasDireccion" 
                :rules="rules.direccion" class="mr-2" @paste.prevent>
              </v-text-field>
              <v-autocomplete ref="refLetraPla" v-model="letraPla" :items="letras" label="Letra" outlined dense  maxlength="5" 
                :menu-props="{offsetY: true, maxHeight: 180}" @keypress="validarLetraEnDireccion" @keydown="tabulador($event, 'letraPla', 'refLetraPla')">
              </v-autocomplete>
            </v-col>
            <!-- Cuadrante de la via generadora -->
            <v-col cols="12" sm="3" md="3" class="pa-0 pl-2">
              <v-autocomplete ref="refOrientacionViaGen" v-model="orientacionViaGen" :items="['NORTE', 'SUR', 'ESTE', 'OESTE']" 
                label="Cuadrante" outlined dense :menu-props="{offsetY: true, maxHeight: 180}" maxlength="5" 
                no-data-text="Sin resultados" @keydown="tabulador($event, 'orientacionViaGen', 'refOrientacionViaGen')">
              </v-autocomplete>
            </v-col>
          </v-row>
          <!-- Tipo-nombre del inmueble y bloque-nombre del adicional -->
          <v-row class="pr-2" align="start">
            <!-- Tipo y nombre del inmueble -->
            <v-col cols="12" sm="6" md="6" class="pa-0 pl-2 d-flex">
              <v-autocomplete ref="refInmueble" v-model="inmueble" :items="tiposDeInmuebles" label="Tipo de inmueble" 
                outlined dense maxlength="15" :menu-props="menuPropsInmueble" @click="viayFieldSizeInmueble()" 
                @keydown="tabulador($event, 'inmueble', 'refInmueble')" class="mr-2" no-data-text="Sin resultados">
              </v-autocomplete>
              <v-text-field v-model="nombreInmueble" label="Número/Nombre" outlined dense maxlength="30" name="nombreInmueble" 
                @keypress="validarLetrasEnNombresAdicionales" :disabled="inmueble === '' || inmueble === null" @paste.prevent>
              </v-text-field>
            </v-col>
            <!-- Bloque y nombre del adicional -->
            <v-col cols="12" sm="6" md="6" class="pa-0 pl-2 d-flex">
              <v-autocomplete ref="refBloque" v-model="bloque" :items="tiposBloques" label="Bloque o interior" 
                outlined dense maxlength="15" :menu-props="menuPropsBloque" @click="viayFieldSizeBloque()" 
                @keydown="tabulador($event, 'bloque', 'refBloque')" class="mr-2" no-data-text="Sin resultados">
              </v-autocomplete>
              <v-text-field v-model="nombreBloque" label="Número/Nombre" outlined dense maxlength="30" name="nombreBloque" 
                @keypress="validarLetrasEnNombresAdicionales" :disabled="bloque === '' || bloque === null" @paste.prevent>
              </v-text-field>
            </v-col>
          </v-row>
          <!-- Información adicional y municipio del adicional -->
          <v-row class="pr-2" align="start">
            <v-col cols="12" sm="6" md="6" class="pa-0 pl-2">
              <v-text-field v-model="informacionAdicional" label="Información adicional" outlined dense 
                maxlength="70" name="informacionUrbana" @keypress="validarLetrasEnInformacionAdicional"  @paste.prevent>
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" class="pa-0 pl-2 d-flex">
              <v-autocomplete ref="refBarrio" id="barrios" v-model="barrio" label="Barrio" dense outlined :rules="rules.direccion" 
                :items="opcionesBarrio" :menu-props="menuPropsBarrio" no-data-text="Sin resultados" :disabled="desabilitarCampoBarrio" 
                @click="listarBarrio()" @focus="barrioFieldSize()" @change="updateNombreBarrio" 
                @keydown="tabulador($event, 'barrio', 'refBarrio')">
              </v-autocomplete>
              <v-text-field v-if="barrio === 'OTRO'" v-model="otroBarrio" label="Nombre" :rules="rules.direccion" outlined dense 
                maxlength="30" class="ml-2" @keypress="validarOtroBarrio" @change="updateNombreBarrio" @paste.prevent>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="pr-2" align="start">
            <v-col cols="12" sm="12" md="12" class="pa-0 pl-2">
              <v-alert color="grey lighten-2" style="color: black; line-height: 1.1rem;" max-width="100%">
                <span v-if="tipoVia || numVia || orientacionVia || viaGen || numPla || orientacionViaGen || informacionAdicional || barrio || otroBarrio || nombreMunicipio">
                  <span v-if="tipoVia !== null && tipoVia !== ''">{{ tipoVia }}</span>
                  <span v-if="numVia !== null && numVia !== ''"> {{ numVia.toUpperCase() }}</span>
                  <span v-if="letraVia !== null && letraVia !== ''">{{ letraVia }}</span>
                  <span v-if="bisVia !== null && bisVia !== ''">{{ bisVia }}</span>
                  <span v-if="orientacionVia !== null && orientacionVia !== ''"> {{ orientacionVia }}</span>
                  <span v-if="viaGen !== null && viaGen !== ''"> # {{ viaGen.toUpperCase() }}</span>
                  <span v-if="letraViaGen !== null && letraViaGen !== ''">{{ letraViaGen }}</span>
                  <span v-if="bisViaGen !== null && bisViaGen !== ''">{{ bisViaGen }}</span>
                  <span v-if="numPla !== null && numPla !== ''"> - {{ numPla.toUpperCase() }}</span>
                  <span v-if="letraPla !== null && letraPla !== ''">{{ letraPla }}</span>
                  <span v-if="orientacionViaGen !== null && orientacionViaGen !== ''"> {{ orientacionViaGen }}</span>
                  <span v-if="barrio !== null && barrio !== '' && barrio !== 'OTRO'">, {{ barrio }}</span>
                  <span v-if="barrio === 'OTRO' && otroBarrio && otroBarrio !== ''">, {{ otroBarrio.toUpperCase() }}</span>
                  <span v-if="nombreMunicipio !== null && nombreMunicipio !== ''">, {{ nombreMunicipio }}</span>
                  <span v-if="inmueble !== null && inmueble !== ''">, {{ inmueble }}</span>
                  <span v-if="nombreInmueble !== null && nombreInmueble !== ''"> {{ nombreInmueble.toUpperCase() }}</span>
                  <span v-if="bloque !== null && bloque !== ''">, {{ bloque }}</span>
                  <span v-if="nombreBloque !== null && nombreBloque !== ''"> {{ nombreBloque.toUpperCase() }}</span>
                  <span v-if="informacionAdicional !== null && informacionAdicional !== ''" style="width: 60% !important;">, {{ organizarInformacion(informacionAdicional) }}</span>
                </span>
                <span v-else>&nbsp;</span>
              </v-alert>
            </v-col>
          </v-row>
          <v-card-actions class="d-flex justify-end pa-0 mt-2">
            <v-btn text color="error" @click="resetDireccion()">Cerrar</v-btn>
            <v-btn color="success" :disabled="!camposDireccionUrbanaLlenos" @click="guardarDireccionUrbana()"> Aceptar </v-btn>
          </v-card-actions>
        </v-form>

        <v-form v-show="direccionRural" ref="formularioDireccionRural" class="mt-6">
          <v-row class="pr-2" align="start">
            <v-col cols="12" sm="6" md="6" class="pa-0 pl-2">
              <v-select v-model="tipoVereda" :items="['VEREDA', 'SECTOR']" label="Seleccione..." 
                :rules="rules.direccion" outlined dense :menu-props="{offsetY:true}">
              </v-select>
            </v-col>
            <v-col cols="12" sm="6" md="6" class="pa-0 pl-2 d-flex">
              <v-autocomplete ref="refVereda" id="vereda" v-model="nombreVereda" label="Nombre" dense outlined :rules="rules.direccion" 
                :items="opcionesBarrio" :menu-props="menuPropsVereda" :disabled="desabilitarCampoBarrio" no-data-text="Sin resultados" 
                @click="listarBarrio()" @focus="veredaFieldSize()" @change="updateNombreBarrio" @keydown="tabulador($event, 'nombreVereda', 'refVereda')">
              </v-autocomplete>
              <v-text-field v-if="nombreVereda === 'OTRO'" v-model="otraVereda" label="Nombre" :rules="rules.direccion" outlined dense 
                maxlength="30" class="ml-2" @keypress="validarOtroBarrio" @change="updateNombreBarrio" @paste.prevent>
              </v-text-field>
            </v-col>
          </v-row>
            <v-row class="pr-2" align="start">
              <v-col cols="12" sm="12" md="12" class="pa-0 pl-2">
                <v-text-field v-model="informacionAdicionalRural" label="Información adicional" outlined dense 
                  maxlength="70" name="informacionRural" @keypress="validarLetrasEnInformacionAdicional" @paste.prevent>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="pr-2" align="start">
              <v-col cols="12" sm="12" md="12" class="pa-0 pl-2">
                <v-alert color="grey lighten-2" style="color: black; line-height: 1.1rem;" max-width="100%">
                  <span v-if="tipoVereda || nombreVereda || otraVereda || nombreMunicipio || informacionAdicionalRural">
                    <span v-if="tipoVereda !== null && tipoVereda !== ''">{{ tipoVereda }}</span>
                    <span v-if="nombreVereda !== null && nombreVereda !== '' && nombreVereda !== 'OTRO'"> {{ nombreVereda.trim() }}</span>
                    <span v-if="nombreVereda === 'OTRO' && otraVereda && otraVereda !== ''"> {{ otraVereda.toUpperCase().trim() }}</span>
                    <span v-if="nombreMunicipio !== null && nombreMunicipio !== ''">, {{ nombreMunicipio }}</span>
                    <span v-if="informacionAdicionalRural !== null && informacionAdicionalRural !== ''">, {{ organizarInformacion(informacionAdicionalRural) }}</span>
                  </span>
                  <span v-else>&nbsp;</span>
                </v-alert>
              </v-col>
            </v-row>
            <v-card-actions class="d-flex justify-end pa-0 mt-2">
              <v-btn text color="error" @click="resetDireccion()">Cerrar</v-btn>
              <v-btn color="success" :disabled="!camposDireccionRuralLlenos" @click="guardarDireccionRural()"> Aceptar </v-btn>
            </v-card-actions>
        </v-form>
      </v-container>
    </v-card-text>
  </v-card>

</template>

<!-- #################################################################################### -->
<!-- ###### Sección de Scripts                                                     ###### -->
<!-- #################################################################################### -->
<script>

export default {
  name: 'AddressForm',
  props: {
    direccion: {
      type: String,
    },
    municipio: {
      type: String,
    },
    idMunicipio: {
      type: String,
    },
    nombreBarrio: {
      type: String,
    },
    nombreBarrioOpcional: {
      type: String,
    },
    titulo: {
      type: String,
    }
  },

  data() {
    return {
      nombreMunicipio: '',
      rules: {
        direccion: [
          v => !!v || "Obligatorio",
        ],
      },
      camposDireccionUrbanaLlenos: false,
      camposDireccionRuralLlenos: false,
      datoAdicionalDireccion: '',
      screenSmaller: false,
      sizeText: null,
      tipoDirecionDelpaciente: null,
      validateProgress: false,
      menuPropsTipoVia: { offsetY: true, maxHeight: 180, maxWidth: null },
      menuPropsBarrio: { offsetY: true, maxHeight: 161, maxWidth: null },
      menuPropsVereda: { offsetY: true, maxHeight: 157, maxWidth: null },
      menuPropsInmueble: { offsetY: true, maxHeight: 180, maxWidth: null },
      menuPropsBloque: { offsetY: true, maxHeight: 180, maxWidth: null },
      menuPropsMunDire: { offsetY: true, maxHeight: 180, maxWidth: null },
      tiposDireccion: '',
      direccionUrbana: false,
      direccionRural: false,
      tipoDireccion: '',
      tiposVias: ["AVENIDA CALLE", "AVENIDA CARRERA", "AUTOPISTA", "AVENIDA", "ANILLO VIAL", "CALLE", "CALLEJÓN", "CARRERA", "CARRETERA", "CIRCUNVALAR", "DIAGONAL", "TRANSVERSAL"],
      tipoVia: '',
      numVia: '',
      letras: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"],
      letraVia: '',
      bisVia: '',
      orientacionVia: '',
      viaGen: '',
      letraViaGen: '',
      bisViaGen: '',
      numPla: '',
      letraPla: '',
      orientacionViaGen: '',
      tiposDeInmuebles: ["EDIFICIO", "HOTEL", "OFICINA", "URBANIZACIÓN", "CASA" ],
      inmueble: '',
      nombreInmueble: '',
      tiposBloques: ["ZONA", "MANZANA", "BLOQUE", "HABITACIÓN", "APARTAMENTO", "PISO" ],
      bloque: '',
      nombreBloque: '',
      informacionAdicional: '',
      barrio: '',
      opcionesBarrio: [],
      tipoVereda: '',
      nombreVereda: '',
      informacionAdicionalRural: '',
      otroBarrio: '',
      otraVereda: '',
      barrioOpcional: '',
    }
  },
  
  mounted() {
    this.llenarFormularioDireccion();
  },

  computed: {
    desabilitarCampoBarrio() {
      return !this.nombreMunicipio || this.nombreMunicipio.trim() === '';
    }
  },

  watch: {
    // Observadores para validar campos de dirección urbana y rural
    tipoVia: 'validarCamposDireccionUrbana',
    numVia: 'validarCamposDireccionUrbana',
    viaGen: 'validarCamposDireccionUrbana',
    numPla: 'validarCamposDireccionUrbana',
    otroBarrio: 'validarCamposDireccionUrbana',
    tipoVereda: 'validarCamposDireccionRural',
    otraVereda: 'validarCamposDireccionRural',
    'inmueble': function (newValue) {
      if (newValue === '' || newValue === null) {
        this.nombreInmueble = '';
      }
    },
    'bloque': function (newValue) {
      if (newValue === '' || newValue === null) {
        this.nombreBloque = '';
      }
    },
    'barrio': function (newValue) {
      this.validarCamposDireccionUrbana();
      if (newValue === '' || newValue !== 'OTRO') {
        this.otroBarrio = '';
      }
    },
    'nombreVereda': function (newValue) {
      this.validarCamposDireccionRural();
      if (newValue === '' || newValue !== 'OTRO') {
        this.otraVereda = '';
      }
    },
  },

  methods: {
    /**
     * Valida que la tecla presionada sea un número.
     * Evita la entrada de caracteres no numéricos.
     * @param {Event} event - Evento de tecla presionada.
     */
    validarLetra(event) {
      const teclaPresionada = event.key
      if (teclaPresionada.match(/[^0-9]/)) {
        event.preventDefault();
      }
    },
    /**
     * Valida que la tecla presionada sea una letra o número.
     * Evita la entrada de caracteres no alfanuméricos.
     * @param {Event} event - Evento de tecla presionada.
     */
    validarLetrasDireccion(event) {
      const teclaPresionada = event.key
      if (teclaPresionada.match(/[^0-9A-Za-záéíóúñÁÉÍÓÚÑ]/)) {
        event.preventDefault();
      }
    },
    /**
     * Valida que la tecla presionada sea una letra, número o caracteres especiales permitidos en información adicional.
     * Evita la entrada de caracteres no permitidos.
     * @param {Event} event - Evento de tecla presionada.
     */
    validarLetrasEnInformacionAdicional(event) {
      const campo = event.target.name;
      const teclaPresionada = event.key;
      const inputValue = event.target.value;

      const coma = (inputValue.match(/,/g) || []).length;
      const guion = (inputValue.match(/-/g) || []).length;
      
      const campoVacio = (tecla) => {
        const esValido = tecla.match(/[0-9A-Za-záéíóúñÁÉÍÓÚÑ]/);
        if (!esValido) {
          event.preventDefault();
        }
      };

      const validarTecla = (tecla, comas, guiones) => {
        const esValido = tecla.match(/[0-9A-Za-záéíóúñÁÉÍÓÚÑ,\- ]/);
        if (!esValido) {
          event.preventDefault();
        } else {
          if (tecla === ',' && comas >= 1) {
            event.preventDefault();
          }
          if (tecla === '-' && guiones >= 1) {
            event.preventDefault();
          }
        }
      };

      if (campo === 'informacionUrbana') {
        if (this.informacionAdicional === '') {
          campoVacio(teclaPresionada);
        } else {
          validarTecla(teclaPresionada, coma, guion);
        }
      } else if(campo === 'informacionRural') {
        if (this.informacionAdicionalRural === '') {
          campoVacio(teclaPresionada);
        } else {
          validarTecla(teclaPresionada, coma, guion);
        }
      } 
    },
    /**
     * Método para validar las teclas presionadas en los campos de entrada.
     * Permite números, letras con tildes, almohadillas, guiones y espacios,
     * con restricciones específicas según el estado del campo.
     *
     * @param {Event} event - El evento de teclado que se está manejando.
     */
    validarLetrasEnNombresAdicionales(event) {
      const campo = event.target.name;
      const teclaPresionada = event.key;
      const inputValue = event.target.value;

      const espacios = (inputValue.match(/ /g) || []).length;
      const teclaGuion = (inputValue.match(/-/g) || []).length;
      const almohadilla = (inputValue.match(/#/g) || []).length;

      const campoVacio = (tecla, numeral) => {
        const esValido = tecla.match(/[0-9A-Za-záéíóúñÁÉÍÓÚÑ#]/);
        if (!esValido) {
          event.preventDefault();
        } else {
          if (tecla === '#' && numeral >= 1) {
            event.preventDefault();
          }
        }
      };

      const validarTecla = (tecla, numeral, guion, espacio) => {
        const esValido = tecla.match(/[0-9A-Za-záéíóúÁÉÍÓÚ#\- ]/);
        if (!esValido) {
          event.preventDefault();
        } else {
          if (tecla === '#' && numeral >= 1) {
            event.preventDefault();
          }
          if (tecla === ' ' && espacio >= 2) {
            event.preventDefault();
          }
          if (tecla === '-' && guion >= 1) {
            event.preventDefault();
          }
        }
      };
      
      if (campo === 'nombreBloque') {
        if (this.nombreBloque === '') {
          campoVacio(teclaPresionada, almohadilla);
        } else {
          validarTecla(teclaPresionada, almohadilla, teclaGuion, espacios);
        }
      } else if(campo === 'nombreInmueble') {
        if (this.nombreInmueble === '') {
          campoVacio(teclaPresionada, almohadilla);
        } else {
          validarTecla(teclaPresionada, almohadilla, teclaGuion, espacios);
        }
      } 
    },
    /**
     * Método para validar las teclas presionadas en los campos de entrada.
     * Permite números, letras con tildes, almohadillas, guiones y espacios,
     * con restricciones específicas según el estado del campo.
     *
     * @param {Event} event - El evento de teclado que se está manejando.
     */
    validarOtroBarrio(event) {
      const teclaPresionada = event.key;
      const inputValue = event.target.value;

      const espacios = (inputValue.match(/ /g) || []).length;

      const campoVacio = (tecla) => {
        const esValido = tecla.match(/[0-9A-Za-záéíóúñÁÉÍÓÚÑ]/);
        if (!esValido || tecla === ' ') {
          event.preventDefault();
        }
      };

      const validarTecla = (tecla, espacios) => {
        const esValido = tecla.match(/[0-9A-Za-záéíóúñÁÉÍÓÚÑ ]/);
        if (!esValido) {
          event.preventDefault();
        } else {
          if (tecla === ' ' && espacios >= 3) {
            event.preventDefault();
          }
        }
      };

      if (inputValue === '') {
        campoVacio(teclaPresionada);
      } else {
        validarTecla(teclaPresionada, espacios);
      }
    },
    /**
     * Valida la entrada en un campo para permitir solo un carácter que sea una letra (a-z, A-Z).
     * 
     * @param {Event} event - Evento de tecla presionada.
     */
    validarLetraEnDireccion(event) {
      const teclaPresionada = event.key;
      const campo = event.target;

      if (!teclaPresionada.match(/^[A-Za-z]$/) || campo.value.length > 0) {
        event.preventDefault();
      }
    },
    /** Configura el tamaño de los campos tipo de vía y orientación de la dirección */
    viayFieldSize() {
      this.menuPropsTipoVia.maxWidth = this.$refs.refTipoVia.$el.offsetWidth;
    },
    /** Configura el tamaño del campo para el tipo de inmueble */
    viayFieldSizeInmueble() {
      this.menuPropsInmueble.maxWidth = this.$refs.refInmueble.$el.offsetWidth;
    },
    /** Configura el tamaño del campo para el tipo de bloque */
    viayFieldSizeBloque() {
      this.menuPropsBloque.maxWidth = this.$refs.refBloque.$el.offsetWidth;
    },
    /** Configura el tamaño del campo barrio en la dirección urbana */
    barrioFieldSize() {
      this.menuPropsBarrio.maxWidth = this.$refs.refBarrio.$el.offsetWidth;
    },
    /** Configura el tamaño del campo barrio en la dirección rural */
    veredaFieldSize() {
      this.menuPropsVereda.maxWidth = this.$refs.refVereda.$el.offsetWidth;
    },
    /**
     * Lista los barrios disponibles en función del municipio seleccionado y los almacena en el array opcionesBarrio.
     * 
     * En caso de error durante la solicitud al servidor, se maneja el error utilizando el método 'observarError'.
     */
    async listarBarrio() {
      if (this.municipio !== '' && this.idMunicipio !== '') {
        try {
          const response = await this.$http.get(`msa-external/public/barrio/list/${this.idMunicipio}`);
          this.opcionesBarrio = response.data.map(item => ({
            text: `${item.barrio}`,
            value: `${item.barrio}`,
            barrio: item.barrio,
            id: `${item.idBarrio}`,
          }));
          this.opcionesBarrio.push({ text: 'OTRO', value: 'OTRO', barrio: 'OTRO', id: null });
        } catch (error) {
          this.observarError(error);
        }
      }
    },
    /**
     * Actualiza el nombre del barrio seleccionado.
     */
    updateNombreBarrio() {
      const barrioActual = this.barrio !== '' ? this.barrio : this.nombreVereda;
      const selectedBarrio = this.opcionesBarrio.find(barrio => barrio.barrio === barrioActual);
      if (selectedBarrio) {
        this.barrioSelecionado = selectedBarrio.text;
        const otroBarrio = (this.otroBarrio !== undefined && this.otroBarrio !== null && this.otroBarrio.trim() !== '') ? this.otroBarrio.toUpperCase() : '';
        const otraVereda = (this.otraVereda !== undefined && this.otraVereda !== null && this.otraVereda.trim() !== '') ? this.otraVereda.toUpperCase() : '';
        this.barrioOpcional = this.barrio === 'OTRO' ? otroBarrio : this.nombreVereda === 'OTRO' ? otraVereda : '';
      } else {
        this.barrioSelecionado = '';
        this.barrioOpcional = '';
      }
    },
    /**
     * Abre el diálogo de dirección y realiza las siguientes acciones:
     * - Verifica si hay una dirección ya establecida en el campo this.direccionLista
     * - Verifica si la dirección proporcionada es urbana o rural.
     * - Si es urbana, procesa la información de la dirección urbana.
     * - Si es rural, procesa la información de la dirección rural.
     * - Actualiza las variables de estado para mostrar u ocultar elementos en el diálogo.
     */
    async llenarFormularioDireccion() {
      this.fieldWidth();
      this.barrioSelecionado = this.nombreBarrio;
      this.nombreMunicipio = this.municipio;
      this.barrioOpcional = this.nombreBarrioOpcional;
      // Verifica si se proporcionó una dirección
      if (this.direccion !== '') {
        const esDireccionUrbana = this.tiposVias.some(opcion => this.direccion.startsWith(opcion));
        this.direccionUrbana = esDireccionUrbana;
        this.direccionRural = !esDireccionUrbana;
        
        if (esDireccionUrbana) {
          let direccionCompleta = this.direccion;
          let nuevaDireccion;
          let indiceNumeral = direccionCompleta.indexOf('#');
          const direccionCompletaSinComa = direccionCompleta.split(',').map(part => part.trim());
          let barrioEnDireccion = '';
          if (direccionCompletaSinComa.length > 1 && this.barrioSelecionado !== null && this.barrioSelecionado !== '') {
            barrioEnDireccion = this.barrioSelecionado !== 'OTRO' ? this.barrioSelecionado : this.barrioOpcional;
          } else {
            barrioEnDireccion = direccionCompletaSinComa[1];
            this.barrioSelecionado = 'OTRO';
            this.barrioOpcional = direccionCompletaSinComa[1];
          }
          if (indiceNumeral !== -1) {
            const barrioEscapado = barrioEnDireccion.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');
            const municipioEscapado = this.nombreMunicipio.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');
            const regexBarrio = new RegExp(',\\s*' + barrioEscapado, 'i');
            const regexMunicipio = new RegExp(',\\s*' + municipioEscapado, 'i');
            let direccionU = direccionCompleta.replace(regexBarrio, '').replace(regexMunicipio, '');
            nuevaDireccion = direccionU.trim();
          } else {
            const municipioEscapado = this.nombreMunicipio.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');
            const regexMunicipio = new RegExp(',\\s*' + municipioEscapado, 'i');
            let direccionR = direccionCompleta.replace(regexMunicipio, '');
            nuevaDireccion = direccionR.trim();
          }
          if (nuevaDireccion.endsWith(',')) {
            nuevaDireccion = nuevaDireccion.slice(0, -1); 
          }
          // Procesa la información de la dirección urbana
          this.direccionUrbana = true;
          this.tiposDireccion = 'urbana';
          this.tipoDireccion = 'U';
          const tipoViaOption = this.tiposVias.find(opcion => nuevaDireccion.startsWith(opcion));
          const direccionSinTipoVia = nuevaDireccion.substring(tipoViaOption.length).trim();
          const partesDireccionSinComa = direccionSinTipoVia.split(',').map(part => part.trim());
          const primeraParte = partesDireccionSinComa[0]; 
          const [parteInicial, resto] = primeraParte.split('#');
          const [parteInicial1, parteInicial2] = parteInicial.trim().split(/\s+/).map(part => part.trim());
          const [despuesNumeral, despuesGuion] = resto.trim().split('-').map(part => part.trim());
          const [parte1, parte2] = despuesGuion.split(/\s+/).map(part => part.trim());
          const partesVia = this.separarNumeroYLetras(parteInicial1.trim());
          const partesViaGen = this.separarNumeroYLetras(despuesNumeral.trim());
          const partesNumPla = this.separarUltimaLetra(parte1);
          
          if (partesDireccionSinComa.length > 1) {
            this.procesarPartesDireccion(partesDireccionSinComa);
          } else {
            this.inmueble = '';
            this.nombreInmueble = '';
            this.bloque = '';
            this.nombreBloque = '';
            this.informacionAdicional = '';
          }
          this.tipoVia = tipoViaOption || '';
          this.numVia = partesVia.numero;
          this.letraVia = partesVia.letra;
          this.bisVia = partesVia.bis;
          this.orientacionVia = parteInicial2 ? parteInicial2.trim() : '';
          this.viaGen = partesViaGen.numero;
          this.letraViaGen = partesViaGen.letra;
          this.bisViaGen = partesViaGen.bis;
          this.numPla = partesNumPla.numero;
          this.letraPla = partesNumPla.letra;
          this.orientacionViaGen = parte2 ? parte2 : '';

          await this.listarBarrio();
          let barrioActual = this.opcionesBarrio.find(barrio => barrio.barrio === this.barrioSelecionado);
          if (typeof barrioActual === 'object') {
            this.barrio = barrioActual.barrio;
            this.otroBarrio = this.barrioOpcional;
          } else {
            this.barrio = null;
            this.otroBarrio = '';
          }
        } else {
          // Procesa la información de la dirección rural
          this.direccionRural = true;
          this.tiposDireccion = 'rural';
          this.tipoDireccion = 'R';
          const [, tipoVereda, informacionAdicional] = this.direccion.match(/(\S+)\s+(.*)/);
          this.tipoVereda = (tipoVereda !== 'VEREDA' && tipoVereda !== 'SECTOR') ? 'SECTOR' : tipoVereda;
          const direccionRularSinComa = informacionAdicional.split(',').map(part => part.trim());
          let barrioEnDireccion = '';
          if (direccionRularSinComa.length > 0 && this.barrioSelecionado !== null && this.barrioSelecionado !== '') {
            barrioEnDireccion = this.barrioSelecionado !== 'OTRO' ? this.barrioSelecionado : this.barrioOpcional;
          } else {
            barrioEnDireccion = direccionRularSinComa[0];
            this.barrioSelecionado = 'OTRO';
            this.barrioOpcional = direccionRularSinComa[0];
          }
          let informacionAdicionalRural = informacionAdicional;
          if (barrioEnDireccion.trim() !== '') {
            informacionAdicionalRural = informacionAdicionalRural.replace(barrioEnDireccion.trim(), '');
          }
          if (this.nombreMunicipio.trim() !== '') {
            informacionAdicionalRural = informacionAdicionalRural.replace(this.nombreMunicipio.trim(), '');
          }
          this.informacionAdicionalRural = informacionAdicionalRural.replace(/^[,\s]+/, '').trim();
          await this.listarBarrio();
          let barrioActual = this.opcionesBarrio.find(barrio => barrio.barrio === this.barrioSelecionado);
          if (typeof barrioActual === 'object') {
            this.nombreVereda = barrioActual.value;
            this.otraVereda = this.barrioOpcional;
          } else {
            this.nombreVereda = '';
            this.otraVereda = '';
          }
        } 
      } else {
        // Si no se proporcionó una dirección, limpia las variables y abre el diálogo en dirección urbana
        this.tipoVia = '';
        this.tipoVereda = '';
        this.nombreVereda = '';
        this.numVia = '';
        this.viaGen = '';
        this.orientacionVia = '';
        this.orientacionViaGen = '';
        this.informacionAdicional = '';
        this.informacionAdicionalRural = '';
        this.numPla = '';
        this.barrio = '';
        this.direccionUrbana = true;
        this.tiposDireccion = 'urbana';
        this.tipoDireccion = 'R';
      }
      this.listarBarrio();
    },
    /**
     * Ajusta el ancho máximo del menú para los campos v-autocomplete
     * para que coincida con el ancho de los campos de entrada correspondientes.
     * Este método asegura que el menú desplegable no exceda el ancho del campo de entrada.
     * Utiliza $nextTick y setTimeout para manejar actualizaciones del DOM y asegurar
     * que los anchos se calculen correctamente después de que el componente se haya renderizado.
     */
    fieldWidth() {
      this.$nextTick(() => {
        if (this.$refs.refTipoVia) {
          setTimeout(() => {
            this.menuPropsTipoVia.maxWidth = this.$refs.refTipoVia.$el.offsetWidth;
          }, 100)
        }
        if (this.$refs.refInmueble) {
          setTimeout(() => {
            this.menuPropsInmueble.maxWidth = this.$refs.refInmueble.$el.offsetWidth;
          }, 100)
        }
        if (this.$refs.refBloque) {
          setTimeout(() => {
            this.menuPropsBloque.maxWidth = this.$refs.refBloque.$el.offsetWidth;
          }, 100)
        }
        if (this.$refs.refBarrio) {
          setTimeout(() => {
            this.menuPropsBarrio.maxWidth = this.$refs.refBarrio.$el.offsetWidth;
          }, 100)
        }
      })
    },
    /**
     * Separa una cadena en una parte numérica y letras finales.
     * 
     * @param {string} cadena - La cadena a ser separada. Puede contener números y letras.
     * @returns {Object} Un objeto con las partes separadas:
     * - `numero`: La parte numérica y letras intermedias.
     * - `letra`: La primera letra de las letras finales.
     * - `bis`: La segunda letra de las letras finales si existe.
     */
    separarNumeroYLetras(cadena) {
      const regex = /^(\d*)(.*?)([A-Z]{1,2})$/i;
      const match = cadena.match(regex);
      if (match) {
        const numeros = match[1];
        const letrasIntermedias = match[2];
        const letrasFinales = match[3];

        if (letrasFinales.length === 2) {
          return {
            numero: numeros + letrasIntermedias,
            letra: letrasFinales[0],
            bis: letrasFinales[1]
          };
        } else if (letrasFinales.length === 1) {
          return {
            numero: numeros + letrasIntermedias.slice(0, -1),
            letra: letrasIntermedias.slice(-1) + letrasFinales,
            bis: ''
          };
        }
      }
      return { numero: cadena, letra: '', bis: '' };
    },
    /**
     * Separa la última letra de una cadena, si existe.
     * 
     * @param {string} cadena - La cadena a ser separada. Puede contener números y una letra al final.
     * @returns {Object} Un objeto con las partes separadas:
     * - `numero`: La parte numérica sin la última letra.
     * - `letra`: La última letra de la cadena si es una letra.
     */
    separarUltimaLetra(cadena) {
      const ultimoCaracter = cadena.slice(-1);
      const esLetra = isNaN(parseInt(ultimoCaracter, 10));
      if (esLetra) {
        const numero = cadena.slice(0, -1);
        const letra = ultimoCaracter;
        return { numero, letra };
      } else {
        return { numero: cadena, letra: '' };
      }
    },
    /**
     * Procesa las partes de una dirección excluyendo la primera posición.
     * 
     * @param {Array} partesDireccionSinComa - Un arreglo de partes de dirección sin comas.
     */
    procesarPartesDireccion(partesDireccionSinComa) {
      const partesSinPosicionCero = partesDireccionSinComa.slice(1);
      if (partesSinPosicionCero.length > 0) {
        let inmueble = '';
        let nombreInmueble = '';
        let bloque = '';
        let nombreBloque = '';
        let informacionAdicional = [];

        partesSinPosicionCero.forEach((parte) => {
          const esInmueble = this.tiposDeInmuebles.some(opcion => parte.startsWith(opcion));
          const esBloque = this.tiposBloques.some(opcion => parte.startsWith(opcion));

          if (esInmueble && !inmueble) {
            inmueble = this.tiposDeInmuebles.find(opcion => parte.startsWith(opcion));
            nombreInmueble = parte.substring(inmueble.length).trim() || '';
          } else if (esBloque && !bloque) {
            bloque = this.tiposBloques.find(opcion => parte.startsWith(opcion));
            nombreBloque = parte.substring(bloque.length).trim() || '';
          } else {
            informacionAdicional.push(parte);
          }
        });

        this.inmueble = inmueble;
        this.nombreInmueble = nombreInmueble;
        this.bloque = bloque;
        this.nombreBloque = nombreBloque;
        this.informacionAdicional = informacionAdicional.join(', ').trim();
      }
    },
    /**
     * Valida si los campos obligatorios de una dirección urbana están llenos.
     * Actualiza la variable de estado `camposDireccionUrbanaLlenos` en consecuencia.
     */
    validarCamposDireccionUrbana() {
      let hayBarrio = false;
      if (this.barrio !== null && this.barrio !== '' && this.barrio !== 'OTRO') {
        hayBarrio = true
      } else {
        if (this.barrio === 'OTRO' && this.otroBarrio !== null && this.otroBarrio !== '') {
          hayBarrio = true
        } else {
          hayBarrio = false
        }
      }
      this.camposDireccionUrbanaLlenos = (this.tipoVia && this.numVia && this.viaGen && this.numPla && hayBarrio);
    },
    /**
     * Valida si los campos obligatorios de una dirección rural están llenos.
     * Actualiza la variable de estado `camposDireccionRuralLlenos` en consecuencia.
     */
    validarCamposDireccionRural() {
      let hayVereda = false;
      if (this.nombreVereda !== null && this.nombreVereda !== '' && this.nombreVereda !== 'OTRO') {
        hayVereda = true
      } else {
        if (this.nombreVereda === 'OTRO' && this.otraVereda !== null && this.otraVereda !== '') {
          hayVereda = true
        } else {
          hayVereda = false
        }
      }
      this.camposDireccionRuralLlenos = this.tipoVereda && hayVereda;
    },
    /**
     * Organiza y normaliza una cadena de texto.
     * @param {string} valor - La cadena de texto que se va a organizar.
     * @returns {string} - La cadena de texto organizada y normalizada.
     */
    organizarInformacion(valor) {
      let informacion = valor.toUpperCase().trimEnd();
      informacion= informacion.replace(/[\s,|-]+$/, '');
      return informacion;
    },
    /**
     * Maneja el evento de presionar la tecla Tab para campos v-autocomplete.
     * Si se presiona la tecla Tab y el valor actual del modelo es null,
     * desenfoca el campo y establece el valor del modelo como una cadena vacía.
     *
     * @param {KeyboardEvent} event - El objeto del evento de teclado.
     * @param {string} model - El nombre de la propiedad del modelo.
     * @param {string} ref - El nombre de la referencia del campo v-autocomplete.
     */
    tabulador(event, model, ref) {
      if (event.key === 'Tab' && this[model] === null) {
        this.$refs[ref].blur();
        this[model] = '';
      }
    },
    /**
     * Guarda la dirección urbana ingresada por el usuario.
     * Limpia el campo de dirección y actualiza el modelo si el formulario es válido.
     * Cierra el diálogo de dirección urbana.
     */
    guardarDireccionUrbana() {
      this.direccionLista = '';
      // Construir la dirección con los componentes ingresados
      let direccion = this.tipoVia + ' ' + this.numVia.toUpperCase();
      if (this.letraVia !== null && this.letraVia !== '') { direccion += this.letraVia}
      if (this.bisVia !== null && this.bisVia !== '') { direccion += this.bisVia }
      if (this.orientacionVia !== null && this.orientacionVia !== '') { direccion += ' ' + this.orientacionVia }
      direccion += ' # ' + this.viaGen.toUpperCase();
      if (this.letraViaGen !== null && this.letraViaGen !== '') { direccion += this.letraViaGen }
      if (this.bisViaGen !== null && this.bisViaGen !== '') { direccion += this.bisViaGen }
      direccion += ' - ' + this.numPla.toUpperCase();
      if (this.letraPla !== null && this.letraPla !== '') { direccion += this.letraPla }
      if (this.orientacionViaGen !== null && this.orientacionViaGen !== '') { direccion += ' ' + this.orientacionViaGen }
      this.barrio !== 'OTRO' ? direccion += ', ' + this.barrio : direccion += ', ' + this.otroBarrio.toUpperCase();
      direccion += ', ' + this.nombreMunicipio;
      if (this.inmueble !== null && this.inmueble !== '') { direccion += ', ' + this.inmueble }
      if (this.nombreInmueble !== null && this.nombreInmueble !== '') { direccion += ' ' + this.nombreInmueble.toUpperCase() }
      if (this.bloque !== null && this.bloque !== '') { direccion += ', ' + this.bloque }
      if (this.nombreBloque !== null && this.nombreBloque !== '') { direccion += ' ' + this.nombreBloque.toUpperCase() }
      if (this.informacionAdicional !== null && this.informacionAdicional !== '') { direccion += ', ' + this.organizarInformacion(this.informacionAdicional) }

      // Actualizar el modelo con la nueva dirección
      this.direccionLista = direccion;
      this.barrioSelecionado = this.barrio !== '' ? this.barrio : '';
      this.tiposDireccion = 'urbana';
      this.tipoDireccion = 'U';
      this.datoAdicionalDireccion = (this.informacionAdicional && this.informacionAdicional !== '') ? this.informacionAdicional.toUpperCase() : '';
      this.direccionFinal();
      this.resetDireccion();
    },
    /**
     * Guarda la dirección rural ingresada por el usuario.
     * Limpia el campo de dirección y actualiza el modelo si el formulario es válido.
     * Cierra el diálogo de dirección rural.
     */
    guardarDireccionRural() {
      this.direccionLista = '';
      const barrioRural = this.nombreVereda !== 'OTRO' ? this.nombreVereda : this.otraVereda.toUpperCase();
      // Construir la dirección con los componentes ingresados
      const dir = `${this.tipoVereda} ${barrioRural.trim()}${ ", " + this.nombreMunicipio}${this.informacionAdicionalRural != '' ? ", " + this.informacionAdicionalRural.toUpperCase() : ''}`;
      // Actualizar el modelo con la nueva dirección
      this.direccionLista = dir;
      this.barrioSelecionado = this.nombreVereda != '' ? this.nombreVereda : '';
      this.tiposDireccion = 'rural';
      this.tipoDireccion = 'R';
      this.datoAdicionalDireccion = (this.informacionAdicionalRural && this.informacionAdicionalRural !== '') ? this.informacionAdicionalRural.toUpperCase() : '';
      this.direccionFinal();
      this.resetDireccion();
    },
    /**
     * Emite un evento 'direccion-guardada' con los detalles finales de la dirección.
     * 
     * Este método se utiliza para enviar los detalles de la dirección del componente AddressForm
     * al componente principal o padre, donde se capturará el evento y se actualizarán
     * las propiedades correspondientes.
     */
    direccionFinal() {
      this.$emit('direccion-guardada', {
        direccionLista: this.direccionLista,
        barrioSelecionado: this.barrioSelecionado,
        barrioOptional: this.barrioOpcional,
        tiposDireccion: this.tiposDireccion,
        tipoDireccion: this.tipoDireccion,
        datoAdicionalDireccion: this.datoAdicionalDireccion,
      });
    },
    /**
     * Restablece los valores de los campos y las variables relacionadas a la dirección del afiliado.
     * Establece los valores predeterminados y cierra los cuadros de diálogo abiertos.
     * Reinicia el estado del componente de observación (refs.observerDireccionRural) y (refs.observerDireccionUrbana).
     */
    resetDireccion(){
      setTimeout(() => {
        this.tipoVia = '';
        this.numVia = '';
        this.letraVia = '';
        this.bisVia = '';
        this.orientacionVia = '';
        this.viaGen = '';
        this.letraViaGen = '';
        this.bisViaGen = '';
        this.numPla = '';
        this.letraPla = '';
        this.orientacionViaGen = '';
        this.barrio = '';
        this.otroBarrio = '';
        this.inmueble = '';
        this.nombreInmueble = '';
        this.bloque = '';
        this.nombreBloque = '';
        this.informacionAdicional = '';
        this.tipoVereda = '';
        this.nombreVereda = '';
        this.otraVereda = '';
        this.informacionAdicionalRural = '';
        this.tiposDireccion = '';
        this.tipoDireccion = '';
        this.barrioSelecionado = '';
        this.direccionLista = '';
        this.datoAdicionalDireccion = '';
        this.direccionRural= false;
        this.direccionUrbana = false;
        if (this.$refs.formularioDireccionUrbana) {
          this.$refs.formularioDireccionUrbana.resetValidation();
        }
        if (this.$refs.formularioDireccionRural) {
          this.$refs.formularioDireccionRural.resetValidation();
        }
      }, 500)
      this.$emit('close-address');
    },
  },
}
</script>
<!-- #################################################################################### -->
<!-- ###### Sección de Estilos                                                     ###### -->
<!-- #################################################################################### -->
<style scoped>
.invisible-radio {
  position: absolute;
  opacity: 0;
  pointer-events: none;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  border: 0;
}
</style>