/*################################################################################## 
###### HERINCOHAN                                                             ###### 
###### @author: Diego Alexander Villalba                                      ###### 
###### @date: Octubre 2023                                                    ###### 
##################################################################################*/

const DispensationMain = () => import('../DispensationMain.vue');
const Dispensation = () => import('../Dispensation.vue');
const MonitorOrdenamiento = () => import('../MonitorOrdenamiento.vue');
const Solicitud = () => import('../Solicitud.vue');
import { Role } from "@/router/role.js";


const dispensationRoutes = [
  {
    path: 'dispensation',
    name: 'modules.pharmaceuticalServicesMain.dispensation',
    component: DispensationMain,
    children: [
      {
        path: 'ambulatorio',
        name: 'modules.pharmaceuticalServicesMain.dispensation.ambulatorio',
        component: Dispensation,
        meta: { authorize: Role.ServiciosFarmaceuticos_Dispensacion && Role.ServiciosFarmaceuticos_Dispensacion_Ambulatorio }
      },
      {
        path: 'intrahospitalario',
        name: 'modules.pharmaceuticalServicesMain.dispensation.intrahospitalario',
        component: MonitorOrdenamiento,
        meta: { authorize: Role.ServiciosFarmaceuticos_Dispensacion && Role.ServiciosFarmaceuticos_Dispensacion_Intrahospitalario }
      },
      {
        path: 'solicitud',
        name: 'modules.pharmaceuticalServicesMain.dispensation.solicitud',
        component: Solicitud,
        meta: { authorize: Role.ServiciosFarmaceuticos_Dispensacion && Role.ServiciosFarmaceuticos_Dispensacion_Solicitud }
      },
    ],
    meta: { authorize: Role.ServiciosFarmaceuticos_Dispensacion }
  },
];


export default dispensationRoutes;