/*################################################################################## 
###### HERINCOHAN                                                             ###### 
###### @author: Diego Alexander Villalba                                      ###### 
###### @date: Octubre 2023                                                    ###### 
##################################################################################*/

const System = () => import('../System.vue');
const UserXContract = () => import('../UserXContract.vue');
const Clientes = () => import('../Clientes.vue');
const Tasks = () => import('../Tasks');
const ParametrizacionTablero = () => import('../ParametrizacionTablero.vue')
import { Role } from "@/router/role.js";


const systemRoutes = [
  {
    path: 'system',
    name: 'modules.settings.system',
    component: System,
    children: [
      {
        path: 'contratos-por-usuario',
        name: 'modules.settings.system.contratosPorUsuario',
        component: UserXContract,
        meta: { authorize: Role.Configuraciones_Sistema_ContratosPorUsuario && Role.Configuraciones_Sistema }
      },
      {
        path: 'clientes',
        name: 'modules.settings.system.clientes',
        component: Clientes,
        meta: { authorize: Role.Configuraciones_Sistema_Clientes && Role.Configuraciones_Sistema }
      },
      {
        path: 'tasks',
        name: 'modules.settings.system.tasks',
        component: Tasks,
        meta: { authorize: Role.Configuraciones_Sistema_Tasks && Role.Configuraciones_Sistema }
      },
      {
        path: 'parametrizacionTablero',
        name: 'modules.settings.system.parametrizacionTablero',
        component: ParametrizacionTablero,
        meta: { authorize: Role.Configuraciones_Sistema_ParametrizacionTablero && Role.Configuraciones_Sistema }
      },
    ],
    meta: { authorize: Role.Configuraciones_Sistema }
  },

];


export default systemRoutes;